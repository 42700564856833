
import { Col, Row, Layout, message } from 'antd'
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react'
import { dbstore } from '../../dataLayer/stores/dbStore';
import { rxUploadStore } from '../../dataLayer/stores/pages/rxUploadStore';
import Triangle from '../Triangle'
import UploadInfo from '../UploadInfo';
import UploadList from '../UploadList';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const TasksRxUploads = observer(({ data }) => {
  useEffect(() => {
    dbstore.getprescriptionTransfers(true)
  }, [])
  return <Row gutter={[8, 8]} style={{ width: '100%' }}>
    <Col span={8}>
      <div style={{ backgroundColor: '#fff', height: '80vh', marginTop: 15, overflow: 'scroll' }} className="drp-card">
        <UploadList data={rxUploadStore.comp1Data} onClick={(id) => { rxUploadStore.setSelectedPtr(id) }} />
      </div>
    </Col>
    <Col span={16}>
      <div style={box}>
        <Row style={{ padding: 20, minHeight: '80vh' }} className="drp-card">
          <UploadInfo data={rxUploadStore.comp2Data}
            onSubmit={async (obj) => {
              console.log(toJS(obj))
              var res = await dbstore.updatePtr(obj)
              if (res == 200) {
                message.success("Updated Successfully!")
              } else {
                message.error("Oops! something went wrong")
              }
            }} />
          <Triangle
            style={{
              position: "absolute",
              top: "25%",
              left: 0,
            }}
          />
          {/* <GroupComponent /> */}
        </Row>
      </div>
    </Col>
  </Row>;
});
export default TasksRxUploads;