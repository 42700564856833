import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Colours } from "../../Colours";
import "./NavLink.css";

const DrNavLink = ({ to, title, Icon, highlight = true }) => {
  // console.log({ to, title, iconName, iconType, otherProps });
  return (
    <NavLink
      to={to}
      className="row ml-0 mr-0 nav-route"
      activeClassName="nav-route-active"
      exact={true}

      style={{ marginLeft: 0, borderLeftWidth: highlight ? 7 : 0, paddingLeft: highlight ? 0 : 20 }}
    //style={{ borderLeft: `${highlight ? 7 : 0}px solid #a3a0fb`, marginLeft: 0 }}
    >
      <Col xs={2}>
        <Icon
          id="icon"
          className="align-self-center"
          color={Colours.maroon}
          style={{ verticalAlign: "text-top" }}
        />
      </Col>
      <Col style={{ justifyContent: "left", color: Colours.primaryText }}>
        {title}
      </Col>
    </NavLink>
  );
};

export default DrNavLink;
