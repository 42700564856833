
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import ReportStatsCard from '../ReportStatsCard';
import VitalChartCarousel from '../VitalChartCarousel';
import { patientReportsStore } from '../../dataLayer/stores/pages/patientReportsStore';
import { observer } from 'mobx-react';
import { dbstore } from '../../dataLayer/stores/dbStore';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const PatientReports = observer(({ props }) => {
  useEffect(() => {

    dbstore.getpatientReports();
    patientReportsStore.setSelectedPeriod('Yearly')


  }, [])
  return <>
    <Row gutter={[8, 8]} style={{ width: '100%', overflow: 'hidden' }}>
      <Col span={24}>
        <div style={box}>
          <ReportStatsCard data={patientReportsStore.comp1Data} />
        </div>
      </Col>
      <Col span={24}>

        {/* <VitalChartCarousel
          data={patientReportsStore.comp2Data}
        /> */}



      </Col>
    </Row>
  </>;
});
export default PatientReports;