
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import RecentRxItem from "./RecentRxItem"
const RecentRx = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = {
      arr: [{ date: "blah", time: "blah", name: "blah", directions: "blah" },
      { date: "blah", time: "blah", name: "blah", directions: "blah" },
      { date: "blah", time: "blah", name: "blah", directions: "blah" }]
    }
  }
  return <Row style={{ padding: 20 }} className="drp-card">
    <h6 style={{ fontWeight: '400' }}>RECENT PRESCRIPTIONS</h6>
    {data.arr.map((item, index) => {
      return <RecentRxItem data={item} onClick={onClick} />
    })}
  </Row>;
});
export default RecentRx;
