
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import TimelogItem from './TimelogItem';
const TimelogItemList = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = {
      arr: [
        { time: "blah", checkin: "blah", checkout: "blah", date: "blah" },
        { time: "blah", checkin: "blah", checkout: "blah", date: "blah" },
        { time: "blah", checkin: "blah", checkout: "blah", date: "blah" },
        { time: "blah", checkin: "blah", checkout: "blah", date: "blah" }
      ]
    };
  }
  return <Row style={{ overflowY: 'scroll', paddingRight: 5, height: '80vh' }}>
    {data.arr.map((item, index) => {
      return <><TimelogItem data={item} onClick={onClick} /> </>;
    })}</Row>;
});
export default TimelogItemList;
