/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PtRxItem = ({ data }) => {
  if (!data) {
    data = { completedRefill: "blah", rxname: "blah" }
  }
  return (
    <svg width={386} height={82.2} style={{ marginTop: 5 }} viewBox="0 0 386 82.2">
      <defs>
        <style>
          {
            "._PtRxItema{fill:#fff;}._PtRxItemb{fill:#6c7b8a;font-size:18px;font-family:Nunito-Regular, Nunito;}._PtRxItemc{fill:#140f26;font-size:20px;}._PtRxItemc,._PtRxItemd{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._PtRxItemd,._PtRxIteme{fill:#a0a0a0;}._PtRxItemd{font-size:12px;}"
          }
        </style>
      </defs>
      <rect className="_PtRxItema" width={386} height={82.2} rx={22} />
      <text className="_PtRxItemb" transform="translate(22 52)">
        <tspan x={0} y={18}>
          {`${data.completedRefill} Refills `}
        </tspan>
      </text>
      <text className="_PtRxItemc" transform="translate(22 37)">
        <tspan x={0} y={0}>
          {`${data.rxname}`}
        </tspan>
      </text>
      <g transform="translate(280.5 33)">
        {/* <text className="_PtRxItemd" transform="translate(29 12)">
          <tspan x={-29.202} y={0}>
            {"View More"}
          </tspan>
        </text> */}
        <path
          className="_PtRxIteme"
          d="M15.614,11.661,11.476,7.527a.778.778,0,0,1,0-1.1.788.788,0,0,1,1.107,0l4.688,4.684a.78.78,0,0,1,.023,1.078L12.586,16.9a.781.781,0,1,1-1.107-1.1Z"
          transform="translate(61.402 -4.086)"
        />
      </g>
    </svg>
  )
};

export default PtRxItem;
