
import { Col, Row, Layout, Timeline, Select } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import TrackRefillInfo from './TrackRefillInfo';
import { Button } from 'antd/lib/radio';
const { Option } = Select;
const Dot = ({ active = false }) => {

  return <div style={{ height: active ? 30 : 20, width: active ? 30 : 20, borderRadius: active ? 15 : 10, backgroundColor: active ? '#FA644E' : '#E4E4E4' }}>

  </div>
}
const Approval = ({ active = false }) => {
  return <div style={{ marginBottom: 20 }}>
    <Select disabled={!active} defaultValue="Approval Sent by Fax" style={{ width: 200, borderRadius: 10 }} onChange={() => { }}>
      <Option value="1">Approval Sent by Fax</Option>

    </Select>
    <Button style={{ borderRadius: 10, color: active ? "#fff" : "#000", backgroundColor: active ? "#23d098 " : "#E4E4E4", border: 0, marginLeft: 20 }}>View Form</Button>
  </div>
}
const TrackRefill = observer(({ data, goBack = () => { } }) => {
  if (!data) {
    data = {};
  }
  return <Row style={{ width: '100%', height: '80vh', padding: 20 }}>
    <Col span={24}>
      <TrackRefillInfo goBack={goBack} />

    </Col>
    <Col span={24}>
      <Timeline >
        <Timeline.Item dot={<Dot active />}><Button className="drp-btn-green" style={{ marginBottom: 30, marginTop: -10, marginLeft: 20 }}>Start Refill Request</Button></Timeline.Item>
        <Timeline.Item dot={<Dot />}><p style={{ fontSize: 15, fontFamily: 'Nunito-Regular', color: '#272D5C' }}>Sent to Doctor</p></Timeline.Item>
        <Timeline.Item dot={<Dot />}><Approval /></Timeline.Item>

        <Timeline.Item dot={<Dot />}><p style={{ fontSize: 15, fontFamily: 'Nunito-Regular', color: '#272D5C' }}>Fill Complted</p></Timeline.Item>
        <Timeline.Item dot={<Dot />}><p style={{ fontSize: 15, fontFamily: 'Nunito-Regular', color: '#272D5C' }}>With main pharmacy</p></Timeline.Item>

      </Timeline>
    </Col>
  </Row>;
});
export default TrackRefill;
