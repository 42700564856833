
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import RRItem from './RRItem';
const RRList = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = {
      arr: [{ refills: "bla4h", dname: "blah", pname: "blah" },
      { refills: "blah", dname: "blah", pname: "blah" },
      { refills: "blah", dname: "blah", pname: "blah" }]
    };
  }
  return <>
    <Row style={{ overflowY: 'scroll', paddingRight: 5, height: '100%' }} >
      {data.arr.map((item, index) => {
        return <><RRItem data={item} onClick={onClick} /></>;
      })}</Row>
  </>;
});
export default RRList;
