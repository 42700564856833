
import { Col, Row, Layout, Checkbox } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { Button } from 'react-bootstrap';
import moment from 'moment'
const TaskItem = observer(({ data, onChange = () => { } }) => {
  if (!data) {
    data = {
      description: 'blah',
      imageResponse: true
    };
  }
  console.log(data.status)
  return <>
    <Row style={{ width: '100%', height: 60 }}>
      <Col span={16}>
        <Checkbox disabled={data.taskDate < moment().startOf('day').format('x')} defaultChecked={data.status == 'completed'} onChange={(e) => { console.log(e.target.checked); onChange({ _id: data.id, status: e.target.checked ? 'completed' : 'pending' }) }} style={{ fontFamily: 'Nunito-Bold', fontSize: 16, color: '#131315' }}>{data.description}</Checkbox>
      </Col>
      {data.imageResponse &&
        <Col style={{ marginLeft: 'auto' }}>
          <Button disabled={data.taskDate < moment().startOf('day').format('x')} className="drp-btn-green" size="large" style={{ border: 0, marginRight: 14 }}> Upload/Edit</Button>
          <Button disabled={data.taskDate < moment().startOf('day').format('x')} className="drp-btn-green" style={{ border: 0, marginRight: 14 }}> View</Button>
        </Col>
      }
    </Row>
  </>;
});
export default TaskItem;
