/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import { dbstore } from "../dataLayer/stores/dbStore";

const ReportDetails = ({ data }) => {
  if (!data) {
    data = { pts: "blah", date: "blah", day: "blah", pv: "blah", rc: "blah", pc: "blah", pf: "blah" }
  }
  return (
    <svg viewBox="0 0 915.01 346.464">
      <defs>
        <style>
          {
            "._ReportDetailsa{fill:#f4f6f9;}._ReportDetailsb{fill:#6c7b8a;font-size:18px;font-family:Nunito-Regular, Nunito;}._ReportDetailsc,._ReportDetailsd{fill:#140f26;font-size:20px;}._ReportDetailsc,._ReportDetailse,._ReportDetailsf{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._ReportDetailsd{font-family:Nunito-Bold, Nunito;font-weight:700;}._ReportDetailse,._ReportDetailsg{fill:#272d5c;}._ReportDetailse{font-size:42px;}._ReportDetailsf,._ReportDetailsh{fill:#fff;}._ReportDetailsf{font-size:16px;}"
          }
        </style>
      </defs>
      <g transform="translate(5922 -701.768)">
        <rect
          className="_ReportDetailsa"
          width={914.679}
          height={165.295}
          rx={15}
          transform="translate(-5921.67 827)"
        />
        <text className="_ReportDetailsb" transform="translate(-5831 753.768)">
          <tspan x={0} y={0} xmlSpace="preserve">
            {`${dbstore.doctorReports.patientsVisited} Patients Served                               `}
          </tspan>
        </text>
        <text className="_ReportDetailsc" transform="translate(-5831 721.768)">
          <tspan x={0} y={0}>
            {`Daily Report for ${data.date},${data.day}`}
          </tspan>
        </text>
        <path
          d="M14.1,2.6a1.212,1.212,0,0,0-1.717,0L5.95,9,3.658,6.763a1.212,1.212,0,0,0-1.717,0L.356,8.351a1.212,1.212,0,0,0,0,1.717l4.805,4.786a1.29,1.29,0,0,0,1.778,0l1.574-1.577L15.8,5.987a1.221,1.221,0,0,0,.009-1.717Zm0,16.074a1.212,1.212,0,0,0-1.717,0L5.95,25.112,3.658,22.881a1.212,1.212,0,0,0-1.717,0L.356,24.465a1.212,1.212,0,0,0,0,1.717l4.793,4.8a1.289,1.289,0,0,0,1.777,0l1.585-1.584L15.8,22.1a1.212,1.212,0,0,0,.009-1.706Zm-7.635,17.5a4.847,4.847,0,1,0,0,9.693,4.847,4.847,0,1,0,0-9.693Zm43.62,1.616H21a1.616,1.616,0,0,0-1.616,1.616V42.64A1.616,1.616,0,0,0,21,44.256h29.08A1.616,1.616,0,0,0,51.7,42.64V39.409A1.616,1.616,0,0,0,50.082,37.793Zm0-32.311H21A1.616,1.616,0,0,0,19.387,7.1v3.231A1.616,1.616,0,0,0,21,11.944h29.08A1.616,1.616,0,0,0,51.7,10.329V7.1A1.616,1.616,0,0,0,50.082,5.482Zm0,16.156H21a1.616,1.616,0,0,0-1.616,1.616v3.231A1.616,1.616,0,0,0,21,28.1h29.08A1.616,1.616,0,0,0,51.7,26.484V23.253A1.616,1.616,0,0,0,50.082,21.638Z"
          transform="translate(-5911.849 704.897)"
        />
        <g transform="translate(-5851.151 841.232)">
          <text className="_ReportDetailsd" transform="translate(38.151 87)">
            <tspan x={-37.8} y={0}>
              {"Patients"}
            </tspan>
            <tspan x={-32.08} y={20}>
              {"Visited"}
            </tspan>
          </text>
          <text className="_ReportDetailse" transform="translate(13.151 42)">
            <tspan x={0} y={0}>
              {`${dbstore.doctorReports.patientsVisited}`}
            </tspan>
          </text>
        </g>
        <g transform="translate(-5400.637 841.232)">
          <text className="_ReportDetailsd" transform="translate(49.637 87)">
            <tspan x={-28.28} y={0}>
              {"Refills"}
            </tspan>
            <tspan x={-49.96} y={20}>
              {"Completed"}
            </tspan>
          </text>
          <text className="_ReportDetailse" transform="translate(36.637 42)">
            <tspan x={0} y={0}>
              {`0`}
            </tspan>
          </text>
        </g>
        <g transform="translate(-5177 841.232)">
          <text className="_ReportDetailsd" transform="translate(50 87)">
            <tspan x={-18.04} y={0}>
              {"PAs"}
            </tspan>
            <tspan x={-49.96} y={20}>
              {"Completed"}
            </tspan>
          </text>
          <text className="_ReportDetailse" transform="translate(37 42)">
            <tspan x={0} y={0}>
              {`0`}
            </tspan>
          </text>
        </g>
        <g transform="translate(-5651.72 841.232)">
          <text className="_ReportDetailsd" transform="translate(62.72 87)">
            <tspan x={-63.01} y={0}>
              {"Prescriptions "}
            </tspan>
            <tspan x={-24.6} y={20}>
              {"Filled"}
            </tspan>
          </text>
          <text className="_ReportDetailse" transform="translate(37.72 42)">
            <tspan x={0} y={0}>
              {`${dbstore.doctorReports.prescriptionsFilled}`}
            </tspan>
          </text>
        </g>
        <text className="_ReportDetailsf" transform="translate(-5899 1036.232)">
          <tspan x={0} y={0}>
            {"Checklist"}
          </tspan>
        </text>
        <rect
          className="_ReportDetailsg"
          width={915}
          height={34}
          rx={17}
          transform="translate(-5922 1014.232)"
        />
        <path
          className="_ReportDetailsh"
          d="M13.7,17.5l5.682-5.923a1.04,1.04,0,0,1,1.517,0,1.161,1.161,0,0,1,0,1.584l-6.438,6.71a1.041,1.041,0,0,1-1.481.033L6.5,13.164a1.158,1.158,0,0,1,0-1.584,1.04,1.04,0,0,1,1.517,0Z"
          transform="translate(-5054.838 1015.246)"
        />
      </g>
    </svg>
  )
};

export default ReportDetails;
