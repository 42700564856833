import { Col, Row, Layout, Menu, Affix } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { dbstore } from "../dataLayer/stores/dbStore";
// import { dbstore } from "../dataLayer/stores/dbStore";
const ProfileMenu = (
    <Affix offsetTop={60}>
        <Menu>
            <Menu.Item key="0"
                onClick={() => {
                    dbstore.logoutFunc()
                }}
            >Logout</Menu.Item>

        </Menu></Affix>
);
export default ProfileMenu;
