
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
  toJS,
} from "mobx";
import { dbstore } from "../dbStore";
import moment from 'moment'
export default class Store {

  comp1 = {}
  comp2 = {}
  comp3 = {}
  comp4 = {}
  comp5 = {}
  comp6 = {}

  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    var obj = {}
    if (dbstore.technicianDetails) {
      obj = {
        name: dbstore.technicianDetails.name,
        age: dbstore.technicianDetails.age || 35,
        gender: dbstore.technicianDetails.gender,
        phone: dbstore.technicianDetails.phone,
        avatar: dbstore.technicianDetails.avatar,
        email: dbstore.technicianDetails.email,
        address: Object.values(dbstore.technicianDetails.address ? dbstore.technicianDetails.address : {}).join(",\n"),

      }

      // obj.address = Object.values(obj.address || {}).join(",\n") || "N/A"
    }
    Object.assign(this.comp1, obj)
    console.log(this.comp1)
    return this.comp1
  }
  get comp2Data() {
    if (!dbstore.technicianDetails) { return }
    var obj = { data: dbstore.technicianDetails.phname, id: dbstore.technicianDetails.phid }
    Object.assign(this.comp2, obj)
    return this.comp2
  }
  get comp3Data() {
    if (!dbstore.sessions) {
      return
    };
    var obj = {
      arr: []
    }
    dbstore.sessions.forEach(item => {
      var hrs = moment(item.checkout).diff(moment(item.checkin), 'hours');
      var mins = moment(item.checkout).diff(moment(item.checkin), 'minutes') % 60

      obj.arr.push({
        checkin: moment(item.checkin).format('hh:mm a'),
        checkout: moment(item.checkout).format('hh:mm a'),
        time: hrs + 'hrs ' + mins + ' minutes',
        date: moment(item.checkin).format('DD MMM YY')

      })
    });
    Object.assign(this.comp3, obj)
    return this.comp3
  }
  get comp4Data() {
    if (!dbstore.technicianDetails) {
      return
    }
    if (!dbstore.technicianDetails.settings) { return }
    var obj = dbstore.technicianDetails.settings;
    Object.assign(this.comp4, obj);
    return this.comp4
  }
  get comp5Data() {
    return this.comp5
  }
  get comp6Data() {
    return this.comp6
  }
}

const profileStore = new Store();
export { profileStore };

