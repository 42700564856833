/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
const PtInfo = ({ data, onEdit = () => { } }) => {
  let history = useHistory();
  if (!data) {
    data = {
      pname: "blah", page: "blah", pgender: "blah",
      paddress: "blah", pemail: "blah", page: "blah", pgender: "blah", pphone: "blah", pname: "blah", onchat: () => { }
    }
  }
  return (
    <svg width={'auto'} viewBox="0 0 407.249 730.5">
      <defs>
        <style>
          {
            "._PtInfoa,._PtInfob{fill:#140f26;}._PtInfoa,._PtInfoi{font-size:20px;}._PtInfoa,._PtInfob,._PtInfof,._PtInfok,._PtInfol{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._PtInfob{font-size:13px;}._PtInfoc{fill:url(#a);}._PtInfod,._PtInfoe{fill:#fff;}._PtInfod{font-size:18px;font-family:Montserrat-Medium, Montserrat;font-weight:500;letter-spacing:0.091em;}._PtInfof,._PtInfog,._PtInfoi,._PtInfok,._PtInfol{fill:#272d5c;}._PtInfof{font-size:14px;}._PtInfoh{fill:#fe5431;}._PtInfoi,._PtInfoj{font-family:Nunito-Regular, Nunito;}._PtInfoj{fill:#a01027;font-size:16px;}._PtInfok,._PtInfol{font-size:22px;}._PtInfol{opacity:0.5;}._PtInfom{fill:#23d098;}._PtInfon{fill:#fa644e;}.shadowFilter{filter:  drop-shadow(0px 0px 10px #bbb);}"
          }
        </style>
        <filter id="f4" x="-20%" y="-60%" width="200%" height="200%">
          <feDropShadow dx="0.2" dy="0.4" stdDeviation="7" floodColor="#ddd" />
        </filter>
        <linearGradient
          id="a"
          x1={0.125}
          y1={-0.865}
          x2={0.718}
          y2={1.268}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#2dc9eb" />
          <stop offset={1} stopColor="#14d2b8" />
        </linearGradient>
      </defs>
      <g transform="translate(6087.249 6430)">
        <text className="_PtInfoa" transform="translate(-5983.565 -6399.514)">
          <tspan x={0} y={0}>
            {`${data.pname}`}
          </tspan>
        </text>
        <text className="_PtInfob" transform="translate(-5983.565 -6377.077)">
          <tspan x={0} y={0}>
            {`${data.page}, ${data.pgender}`}
          </tspan>
        </text>
        <circle
          className="_PtInfoc"
          cx={37.75}
          cy={37.75}
          r={37.75}
          transform="translate(-6086.749 -6430)"
        />
        <text className="_PtInfod" transform="translate(-6048.75 -6386.077)">
          <tspan x={-14.219} y={0}>
            {data.alt}
          </tspan>
        </text>

        <g transform="translate(-7005.249 -6612.51)"
          cursor="pointer"
          onClick={() => { history.push("/patientReports") }}>
          <rect
            className="_PtInfoe"
            width={130}
            height={44}
            rx={22}
            transform="translate(920 286)"
            filter="url(#f4)"

          />
          <text className="_PtInfof" transform="translate(970.346 312.51)">
            <tspan x={0} y={0}>
              {"Reports"}
            </tspan>
          </text>
          <g transform="translate(945.25 299.51)" >
            <path
              className="_PtInfog"
              d="M32.494,7.969a1.487,1.487,0,0,0,1.034-.42L35,8.286a1.463,1.463,0,0,0-.018.181,1.494,1.494,0,0,0,2.988,0,1.476,1.476,0,0,0-.15-.64l2-2a1.476,1.476,0,0,0,.64.15,1.5,1.5,0,0,0,1.494-1.494,1.472,1.472,0,0,0-.075-.443l1.738-1.3a1.493,1.493,0,1,0-.667-1.243,1.472,1.472,0,0,0,.075.443l-1.738,1.3a1.488,1.488,0,0,0-2.172,1.883l-2,2a1.468,1.468,0,0,0-1.674.27L33.97,6.656a1.463,1.463,0,0,0,.018-.181,1.494,1.494,0,1,0-1.494,1.494Zm0,0"
              transform="translate(-29.971 0)"
            />
            <path
              className="_PtInfog"
              d="M16.5,161.024h-.531V150.5a.5.5,0,0,0-.5-.5H13.481a.5.5,0,0,0-.5.5v10.526h-1v-7.538a.5.5,0,0,0-.5-.5H9.5a.5.5,0,0,0-.5.5v7.538H8v-3.553a.5.5,0,0,0-.5-.5H5.512a.5.5,0,0,0-.5.5v3.553h-1v-5.545a.5.5,0,0,0-.5-.5H1.527a.5.5,0,0,0-.5.5v5.545H.5a.5.5,0,1,0,0,1h16a.5.5,0,1,0,0-1Zm0,0"
              transform="translate(0 -145.019)"
            />
          </g>
        </g>


        <g transform="translate(-6993.352 -6613)"
          cursor="pointer"
          onClick={() => { history.push("/patientVitals") }}>
          <rect
            className="_PtInfoe"
            width={130}
            height={44}
            rx={22}
            transform="translate(1045 286)"
            filter="url(#f4)"

          />

          <text className="_PtInfof" transform="translate(1101.75 313)">
            <tspan x={0} y={0}>
              {"Vitals"}
            </tspan>
          </text>
          <g transform="translate(1078.453 301)">
            <g transform="translate(0 0)">
              <path
                className="_PtInfoh"
                d="M12.578,50.278a4.579,4.579,0,0,0-4.018,2.38,4.582,4.582,0,0,0-4.018-2.38A4.582,4.582,0,0,0,.582,52.687a5.84,5.84,0,0,0,.134,5.306L.722,58H4.476l.861-1.55a.46.46,0,0,1,.8,0l1.58,2.825,2.444-5.144a.46.46,0,0,1,.848.04L12.4,58H16.4l.006-.011a5.84,5.84,0,0,0,.136-5.306A4.586,4.586,0,0,0,12.578,50.278Z"
                transform="translate(0 -50.278)"
              />
              <path
                className="_PtInfoh"
                d="M94.121,403.632l-.853-2.354-2.262,4.761a.461.461,0,0,1-.818.028l-1.644-2.941-.318.572a.462.462,0,0,1-.4.237H84.354a32.367,32.367,0,0,0,6.692,6.058.553.553,0,0,0,.633,0,32.474,32.474,0,0,0,6.692-6.058H94.555A.461.461,0,0,1,94.121,403.632Z"
                transform="translate(-82.803 -394.823)"
              />
            </g>
          </g>
        </g>
        <g transform="translate(-7011.21 -6614)"
          cursor="pointer"
          onClick={() => { history.push("/patientCalendar") }}
        >
          <rect
            className="_PtInfoe"
            width={130}
            height={44}
            rx={22}
            transform="translate(1200 286)"
            filter="url(#f4)"

          />
          <text className="_PtInfof" transform="translate(1239.293 314)">
            <tspan x={0} y={0}>
              {"RxCalender"}
            </tspan>
          </text>
          <path
            d="M0,15.869A1.642,1.642,0,0,0,1.642,17.51H13.68a1.642,1.642,0,0,0,1.642-1.642v-9.3H0Zm10.944-6.7a.412.412,0,0,1,.41-.41h1.368a.412.412,0,0,1,.41.41v1.368a.412.412,0,0,1-.41.41H11.354a.412.412,0,0,1-.41-.41Zm0,4.378a.412.412,0,0,1,.41-.41h1.368a.412.412,0,0,1,.41.41v1.368a.412.412,0,0,1-.41.41H11.354a.412.412,0,0,1-.41-.41ZM6.566,9.165a.412.412,0,0,1,.41-.41H8.345a.412.412,0,0,1,.41.41v1.368a.412.412,0,0,1-.41.41H6.977a.412.412,0,0,1-.41-.41Zm0,4.378a.412.412,0,0,1,.41-.41H8.345a.412.412,0,0,1,.41.41v1.368a.412.412,0,0,1-.41.41H6.977a.412.412,0,0,1-.41-.41ZM2.189,9.165a.412.412,0,0,1,.41-.41H3.967a.412.412,0,0,1,.41.41v1.368a.412.412,0,0,1-.41.41H2.6a.412.412,0,0,1-.41-.41Zm0,4.378a.412.412,0,0,1,.41-.41H3.967a.412.412,0,0,1,.41.41v1.368a.412.412,0,0,1-.41.41H2.6a.412.412,0,0,1-.41-.41ZM13.68,2.189H12.038V.547A.549.549,0,0,0,11.491,0H10.4a.549.549,0,0,0-.547.547V2.189H5.472V.547A.549.549,0,0,0,4.925,0H3.83a.549.549,0,0,0-.547.547V2.189H1.642A1.642,1.642,0,0,0,0,3.83V5.472H15.321V3.83A1.642,1.642,0,0,0,13.68,2.189Z"
            transform="translate(1219.209 301)"
          />
        </g>
        <g transform="translate(-6085.812 -5886.5)">
          <path
            className="_PtInfoe"
            d="M27.564,0H359c15.223,0,27.564,13.2,27.564,29.474V70.449c0,16.278-12.341,29.474-27.564,29.474H27.564C12.341,99.923,0,86.727,0,70.449V29.474C0,13.2,12.341,0,27.564,0Z"
            transform="translate(0 0)"
          />
          <foreignObject className="_PtInfoi" height={88} width={250}
            style={{
              // whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            transform="translate(67.003 10.861)">
            <p >
              {`${data.paddress}`}
            </p>
          </foreignObject>
          <path
            d="M13.242,0A5.793,5.793,0,0,0,7.449,5.793c0,2.587,3.786,7.3,5.237,9.013a.725.725,0,0,0,1.113,0c1.451-1.711,5.237-6.426,5.237-9.013A5.793,5.793,0,0,0,13.242,0Zm0,7.725a1.931,1.931,0,1,1,1.931-1.931A1.931,1.931,0,0,1,13.242,7.725ZM.925,9.929A1.472,1.472,0,0,0,0,11.3v11.51a.736.736,0,0,0,1.009.683L7.357,20.6V9.882A13.924,13.924,0,0,1,6.38,7.748Zm12.317,6.608a2.2,2.2,0,0,1-1.679-.78c-.9-1.067-1.865-2.282-2.735-3.528V20.6l8.828,2.943V12.231c-.87,1.246-1.831,2.461-2.735,3.528A2.2,2.2,0,0,1,13.242,16.538ZM25.476,7.41,19.128,10.3V23.542l6.432-2.573a1.471,1.471,0,0,0,.925-1.366V8.093A.736.736,0,0,0,25.476,7.41Z"
            transform="translate(28.869 34.763)"
          />
          <text className="_PtInfoj" transform="translate(337.046 55.346)" cursor="pointer" onClick={() => onEdit()}>
            <tspan x={-17.4} y={0}>
              {"EDIT"}
            </tspan>
          </text>
        </g>
        <g transform="translate(-6087.249 -6038.5)">
          <path
            className="_PtInfoe"
            d="M24.874,0H361.688c13.738,0,24.874,11.908,24.874,26.6s-11.137,26.6-24.874,26.6H24.874C11.137,53.2,0,41.288,0,26.6S11.137,0,24.874,0Z"
            transform="translate(0 0)"
          />
          <foreignObject className="_PtInfoi" width={250} height={50}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            transform="translate(62.978 0.857)">
            <p style={{ wordWrap: 'break-word', lineHeight: 1, marginTop: 10 }}>{`${data.pemail}`}</p>
          </foreignObject>
          {/* <text className="_PtInfoi" transform="translate(112.978 30.857)">
            <tspan x={-43.27} y={0}>
              {`${data.pemail}`}
            </tspan>
          </text> */}
          <path
            d="M28.307,6H5.812a2.808,2.808,0,0,0-2.8,2.812L3,25.683A2.82,2.82,0,0,0,5.812,28.5h22.5a2.82,2.82,0,0,0,2.812-2.812V8.812A2.82,2.82,0,0,0,28.307,6Zm0,5.624L17.06,18.654,5.812,11.624V8.812l11.248,7.03,11.248-7.03Z"
            transform="translate(19.101 7.897)"
          />
          <text className="_PtInfoj" transform="translate(338.484 31.626)" cursor="pointer" onClick={() => onEdit()}>
            <tspan x={-17.4} y={0}>
              {"EDIT"}
            </tspan>
          </text>
        </g>
        <path
          className="_PtInfoe"
          d="M21.932,0H156.068C168.181,0,178,11.1,178,24.8s-9.819,24.8-21.932,24.8H21.932C9.819,49.6,0,38.5,0,24.8S9.819,0,21.932,0Z"
          transform="translate(-6085.497 -6111.5)"
        />
        <path
          className="_PtInfoe"
          d="M21.932,0H156.068C168.181,0,178,11.1,178,24.8s-9.819,24.8-21.932,24.8H21.932C9.819,49.6,0,38.5,0,24.8S9.819,0,21.932,0Z"
          transform="translate(-5878.687 -6110.905)"
        />
        <text className="_PtInfok" transform="translate(-6026.544 -6079.137)">
          <tspan x={-40.238} y={0}>
            {`${data.page}`}
          </tspan>
        </text>
        <text className="_PtInfok" transform="translate(-5803.249 -6078.104)">
          <tspan x={-56.683} y={0}>
            {`${data.pgender}`}
          </tspan>
        </text>
        <text className="_PtInfol" transform="translate(-5949.249 -6218.304)">
          <tspan x={-137.566} y={0}>
            {"PERSONAL INFORMATION"}
          </tspan>
        </text>
        <g transform="translate(-6085.812 -5962.5)">
          <path
            className="_PtInfoe"
            d="M24.874,0H361.688c13.738,0,24.874,11.908,24.874,26.6s-11.137,26.6-24.874,26.6H24.874C11.137,53.2,0,41.288,0,26.6S11.137,0,24.874,0Z"
            transform="translate(0 0)"
          />
          <text className="_PtInfoi" transform="translate(121.676 31.759)">
            <tspan x={-47.65} y={0}>
              {`${data.pphone}`}
            </tspan>
          </text>
          <path
            d="M22.87,16.636l-5.15-2.207a1.1,1.1,0,0,0-1.287.317l-2.281,2.786A17.043,17.043,0,0,1,6,9.385L8.791,7.1a1.1,1.1,0,0,0,.317-1.287L6.9.667A1.111,1.111,0,0,0,5.637.028L.855,1.132A1.1,1.1,0,0,0,0,2.207,21.332,21.332,0,0,0,21.335,23.542a1.1,1.1,0,0,0,1.076-.855l1.1-4.782a1.117,1.117,0,0,0-.644-1.269Z"
            transform="translate(27.791 13.488)"
          />
          <text className="_PtInfoj" transform="translate(337.046 31.154)" cursor="pointer" onClick={() => onEdit()}>
            <tspan x={-17.4} y={0}>
              {"EDIT"}
            </tspan>
          </text>
        </g>
        <rect
          className="_PtInfoe"
          width={387.828}
          height={53.248}
          rx={26.624}
          transform="translate(-6087.077 -6187.5)"
        />
        <path
          d="M12.229,13.971a6.934,6.934,0,0,0,6.879-6.986,6.879,6.879,0,1,0-13.757,0A6.934,6.934,0,0,0,12.229,13.971Zm6.114,1.552H15.711a8.2,8.2,0,0,1-6.965,0H6.114A6.162,6.162,0,0,0,0,21.733v.776a2.311,2.311,0,0,0,2.293,2.329H22.164a2.311,2.311,0,0,0,2.293-2.329v-.776A6.162,6.162,0,0,0,18.343,15.524Z"
          transform="translate(-6068.978 -6174.846)"
        />
        {/* <g transform="translate(-6032.192 -5758.5)">
          <ellipse
            className="_PtInfom"
            cx={30.5}
            cy={29.5}
            rx={30.5}
            ry={29.5}
            transform="translate(-0.057 0)"
          />
          <path
            className="_PtInfoe"
            d="M27.08,19.7l-6.1-2.613a1.307,1.307,0,0,0-1.524.376l-2.7,3.3A20.18,20.18,0,0,1,7.11,11.113l3.3-2.7a1.3,1.3,0,0,0,.376-1.524L8.172.79a1.315,1.315,0,0,0-1.5-.757L1.013,1.34A1.307,1.307,0,0,0,0,2.613,25.259,25.259,0,0,0,25.262,27.875a1.307,1.307,0,0,0,1.274-1.013L27.843,21.2a1.322,1.322,0,0,0-.763-1.5Z"
            transform="translate(16.445 15.396)"
          />
        </g> */}
        <g transform="translate(-5921.833 -5755.723)"
          onClick={data.onchat}>
          <path
            className="_PtInfon"
            d="M46.024,36.159a2.914,2.914,0,0,1,.4-1.467,4.051,4.051,0,0,1,.253-.373,19.487,19.487,0,0,0,3.319-10.86c.036-11.088-9.32-20.084-20.89-20.084A20.746,20.746,0,0,0,8.622,19.358a19.326,19.326,0,0,0-.445,4.113A20.4,20.4,0,0,0,28.742,43.807a24.767,24.767,0,0,0,5.676-.926c1.359-.373,2.706-.866,3.055-1a3.179,3.179,0,0,1,1.118-.2,3.127,3.127,0,0,1,1.215.241l6.819,2.417a1.626,1.626,0,0,0,.469.12.957.957,0,0,0,.962-.962A1.545,1.545,0,0,0,48,43.17Z"
            transform="translate(0.037 -3.375)"
          />
          <path
            className="_PtInfon"
            d="M35.906,38.037c-.433.12-.986.253-1.587.385a22.2,22.2,0,0,1-4.089.541A20.4,20.4,0,0,1,9.665,18.627a22.737,22.737,0,0,1,.18-2.574c.072-.517.156-1.034.277-1.539.12-.541.265-1.082.421-1.612l-.962.854A17.9,17.9,0,0,0,3.375,27.214a17.7,17.7,0,0,0,2.983,9.862c.277.421.433.746.385.962s-1.431,7.456-1.431,7.456a.965.965,0,0,0,.325.926.98.98,0,0,0,.613.216.863.863,0,0,0,.349-.072l6.747-2.658a1.879,1.879,0,0,1,1.443.024,20.241,20.241,0,0,0,7.3,1.443,18.888,18.888,0,0,0,14.444-6.626s.385-.529.83-1.155C36.916,37.749,36.411,37.9,35.906,38.037Z"
            transform="translate(-3.375 3.393)"
          />
        </g>
        {/* <g transform="translate(-5812.489 -5758.5)">
          <ellipse
            className="_PtInfom"
            cx={30}
            cy={29.5}
            rx={30}
            ry={29.5}
            transform="translate(0.24 0)"
          />
          <g transform="translate(11.455 18.257)">
            <path
              className="_PtInfoe"
              d="M33.5,10.8a1.513,1.513,0,0,0-.823.239L25.02,15.874a.758.758,0,0,0-.354.638v6.729a.758.758,0,0,0,.354.638l7.659,4.835a1.591,1.591,0,0,0,.823.239h1.842a.754.754,0,0,0,.761-.753V11.553a.76.76,0,0,0-.761-.753Z"
              transform="translate(3.563 -8.533)"
            />
            <path
              className="_PtInfoe"
              d="M23.634,31.668H6.81a4.575,4.575,0,0,1-4.56-4.551V13.551A4.567,4.567,0,0,1,6.8,9H23.625a4.567,4.567,0,0,1,4.551,4.551V27.108A4.556,4.556,0,0,1,23.634,31.668Z"
              transform="translate(-2.25 -9)"
            />
          </g>
        </g> */}
        <text className="_PtInfok" transform="translate(-5967.249 -6152.137)">
          <tspan x={-49.511} y={0}>
            {`${data.pname}`}
          </tspan>
        </text>
      </g>
    </svg>
  )
};

export default PtInfo;
