
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import PAItem from './PAItem';
const PAList = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = {
      arr: [
        { refills: "blah", dname: "blah", pname: "blah" },
        { refills: "blah", dname: "blah", pname: "blah" },
        { refills: "blah", dname: "blah", pname: "blah" },
        { refills: "blah", dname: "blah", pname: "blah" }
      ]
    };
  }
  return <>
    <Row style={{ overflowY: 'scroll', paddingRight: 5, }} >
      {data.arr.map((item, index) => {
        return <><PAItem data={item} onClick={onClick} /></>;
      })}</Row>
  </>;
});
export default PAList;
