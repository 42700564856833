import { Link } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../../assets/images/logo@2x.png";
import logoName from "../../assets/images/logoname@2x.png";
import DrNavLink from "./NavLink";
import patientIcon from "../../assets/images/patient.png";
import { HiOutlineHome, HiSupport } from "react-icons/hi";
import { Colours } from "../../Colours";
import { BiTask } from "react-icons/bi";
import { RiBarChart2Fill, RiMailLine, RiBarcodeLine } from "react-icons/ri";
import { IoPersonOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useLocation
} from "react-router-dom";

import "./NavLink.css";

import TimeCard from "../TimeCard";
import { homeStore } from "../../dataLayer/stores/pages/homeStore";
const SideNav = (props) => {

  let location = useLocation();
  useEffect(() => {
    setshowtasks(['/taskRefillRequests', '/taskPriorAuths', '/tasksRxUploads'].includes(location.pathname))
  }, [location])
  const [showtasks, setshowtasks] = useState(['/taskRefillRequests', '/taskPriorAuths', '/tasksRxUploads'].includes(location.pathname));
  return (
    <div
      className="p-0 ml-2 bg-white full-height "
      style={{ overflow: "hidden" }}
    >
      <Link to="/" className="navbar-brand  mr-0 m-0 ">
        <Container >
          <Row className="pt-3 mb-1 pb-3" style={{ justifyContent: 'center', backgroundColor: '#f5f5f5' }}>
            <Col xs={9}>
              <Image src={logo} fluid />
            </Col>
            <Col xs={9}>
              <Image src={logoName} fluid />
            </Col>
          </Row>
        </Container>
      </Link>
      <DrNavLink to="/home" title={"Home"} Icon={HiOutlineHome}></DrNavLink>

      <div
        // className="row ml-0 mr-0 nav-route cursor"
        role="button"
        className={`${showtasks ? 'row ml-0 mr-0 nav-route cursor nav-route-active' : 'row ml-0 mr-0 nav-route cursor'}`}
        style={{ marginLeft: 0 }}
        onClick={() => {
          setshowtasks(!showtasks);
        }}
      >

        <Col xs={2}>
          <BiTask
            id="icon"
            className="align-self-center"
            color={Colours.maroon}
            style={{ verticalAlign: "text-top" }}
          />
        </Col>
        <Col style={{ justifyContent: "left", color: Colours.primaryText }}>
          Tasks
        </Col>
      </div>
      <div

        style={{

          // visibility: showtasks ? "visible" : "hidden",
          overflow: 'hidden',
          height: showtasks ? "160px" : 0,
          transition: '.2s '

        }}
      >
        <DrNavLink
          to="/taskRefillRequests"
          title={"Refill Requests"}
          Icon={BiTask}
          highlight={false}
        ></DrNavLink>
        <DrNavLink
          to="/taskPriorAuths"
          title={"Prior Auths"}
          Icon={BiTask}
          highlight={false}
        ></DrNavLink>
        <DrNavLink
          to="/tasksRxUploads"
          title={"Rx Uploads"}
          Icon={BiTask}
          highlight={false}
        ></DrNavLink>
      </div>

      <DrNavLink
        to="/patients"
        title={"Patients"}
        Icon={() => <Image src={patientIcon} />}
      ></DrNavLink>
      <DrNavLink
        to="/reports"
        title={"Reports"}
        Icon={FaRegCalendarAlt}
      ></DrNavLink>
      <DrNavLink
        to="/profile"
        title={"Profile"}
        Icon={IoPersonOutline}
      ></DrNavLink>

      <DrNavLink to="/help" title={"Help Center"} Icon={HiSupport}></DrNavLink>
      {/* <DrNavLink
        to="/settings"
        title={"Settings"}
        Icon={IoMdSettings}
      ></DrNavLink> */}
      <div style={{ margin: 40, marginTop: 10 }}>
        <TimeCard data={homeStore.timeCardData} />
      </div>
    </div>
  );
};

export default SideNav;
