
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { dbstore } from "../dbStore";

export default class Store {

  comp1 = { selected: 'bloodPressure' }
  comp2 = {}
  comp3 = {}
  constructor() {
    makeAutoObservable(this, {
      setComp1: action
    });
  }

  setComp1(key) {
    this.comp1.selected = key;
    dbstore.getpatientVitalRecords(key, 10, 0)
  }
}

const vitalsActionsStore = new Store();
export { vitalsActionsStore };

