import React, { useEffect, useRef } from "react";
import Chart from "chart.js";
import { createStackedBarData } from "../Common.fns";
import { inject, observer } from "mobx-react";

import { Bar } from "react-chartjs-2";
import { toJS } from "mobx";

const ReportsChart = observer(({ data = [] }) => {
  var unAnsweredList = []
  var missedList = []
  var takenList = []
  var labels = []
  // console.log(toJS(data))

  for (var i = 0; i < data.length; i++) {
    unAnsweredList.push(data[i].unanswered)
    missedList.push(data[i].missed)
    takenList.push(data[i].taken)
    labels.push(i)
  }
  const graphdata = {
    labels: labels,
    datasets: [
      {
        label: "UnAnswered",
        data: unAnsweredList,
        backgroundColor: "#5578E3",
      },
      {
        label: "Missed",
        data: missedList,
        backgroundColor: "#FD532F",
      },
      {
        label: "Taken",
        data: takenList,
        backgroundColor: "#23D098",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
      },
    },
    //responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRation: false,
  };

  return (
    <Bar
      data={graphdata}
      // width={100}
      height={160}
      options={options}
    />
  );
});

export default ReportsChart;
