import { Col, Row, Layout, Input, Empty } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import Styles from '../Styles/HomeStyle.module.css';
import DrListItem from '../DrListItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react';
import DropInput from '../DropInput';


import HelpStore from '../../dataLayer/stores/pages/help';
import { dbstore } from '../../dataLayer/stores/dbStore';
import _ from 'lodash';

var box = {
  // minHeight: 100,
  backgroundColor: "white",
  margin: 10,
  // width:"fit-content",
  padding: 20,
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1"
};
const HelpCenter = observer(({ props }) => {
  const messageEnd = useRef(null);
  const inputMessage = useRef(null);
  const [sort, setSort] = useState('None');
  const [nav, setNav] = useState([true, false, false]);
  const handleClickNav = (index) => {
    var temp = [false, false, false];
    temp[index] = true;
    setNav(temp);
  }
  // const classcont = useRef(0);
  // const scrollToMyRef = () => {
  //   const scroll =
  //     classcont.current.scrollHeight -
  //     classcont.current.clientHeight;
  //     console.log(scroll)
  //   classcont.current.scrollTo(0, scroll);
  // };
  useEffect(() => {
    if (HelpStore.retrieved == 0) {
      HelpStore.getConversations()
    }
    const listener = HelpStore.listener();

    var func = () => {
      messageEnd.current.scrollIntoView({ behavior: "smooth" })
    }
    HelpStore.setSTB(
      func

    )

  }, [])
  return (
    <div style={Styles?.main}>
      <Row gutter={[32, 8]} style={{ padding: "0em 0em" }}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Help Center</h2>
            </div>

          </div>
        </Col>

        <Col span={10}>
          {/* <div className={Styles.topnav}>
            <h6 className={nav[0] && Styles.selectednav} onClick={() => handleClickNav(0)}>Doctors</h6>
            <h6 className={nav[1] && Styles.selectednav} onClick={() => handleClickNav(1)}>Technicians</h6>
            <h6 className={nav[2] && Styles.selectednav} onClick={() => handleClickNav(2)}>Patients</h6>
          </div> */}
          {/* <Row>
            <Col span={24}> */}
          <div style={box} className={Styles.chatside}>

            {/* <div>
              <DropInput
                onChange={HelpStore.handleSearch} />
            </div> */}
            <div className={Styles.messagebox}>
              {
                HelpStore.users.map((item, index) => {
                  return (

                    <DrListItem item={item} onClick={() => {
                      HelpStore.handleUserClick(item)
                    }} />
                  );
                })
              }
            </div>
          </div>
          {/* </Col>
          </Row> */}

        </Col>
        <Col span={14}>
          <div className={Styles.rightbox + ' ' + Styles.chatside} style={{ ...box, width: '700px' }}>
            {HelpStore.currentUser ? <> <div className={Styles.chatheader}>
              <div>
                <div style={{
                  justifyContent: 'center', paddingTop: 10,
                  alignItems: 'center', backgroundColor: 'rebeccapurple', width: 50, height: 50, borderRadius: 25
                }}>
                  {/* <img></img> */}
                  <div style={{
                    fontSize: 19,
                    textAlign: 'center', color: "#fff", justifyContent: 'center'
                  }}>{HelpStore.currentUserName[0]}</div>
                </div>
                <div>
                  <h5>{HelpStore.currentUserName}</h5>
                  <div>{_.capitalize(HelpStore.currentUserType)}</div>
                </div>
              </div>
            </div>
              <div className={Styles.messagebox}>
                {
                  HelpStore.messages?.map((item, index) => (
                    <div>
                      {item?.user === 0 ? <div>
                        <div className={Styles.reciever}>
                          {item?.content}
                        </div>
                        <div>
                          {item?.time}
                        </div>
                      </div> :
                        <div style={{ float: "right" }}>
                          <div className={Styles.sender}>
                            {item?.content}
                          </div>
                          <div>
                            {item?.time}
                          </div>
                        </div>}
                    </div>
                  ))
                }
                <div style={{ backgroundColor: 'red', height: 0, marginTop: 100, width: 0 }}
                  ref={messageEnd}>

                </div>
              </div>
              <div className={Styles.chatinput}>
                <input
                  ref={inputMessage}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      HelpStore.sendNewMessage(e.target.value);
                      messageEnd.current.scrollIntoView({ behavior: "smooth" });
                      inputMessage.current.value = '';

                    }
                  }} placeholder='Type your message here...'></input>
                <div>
                  <FontAwesomeIcon icon={"paperclip"} size={"lg"} />
                </div>
              </div></> : <Empty />}
          </div>
        </Col>
      </Row>
    </div>
  );
});
export default HelpCenter;