
import { Col, Row, Layout } from 'antd'
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react'
import { pauthActionStore } from '../../dataLayer/stores/actions/pauthActionStore';
import { pauthStore } from '../../dataLayer/stores/pages/pauthStore';
import PAList from '../PAList';
import RxDetails from '../RxDetails';
import Triangle from '../Triangle';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const TaskPriorAuths = observer(({ props }) => {
  return <><Row gutter={[8, 8]}>
    <Col span={8}>
      <div style={{ backgroundColor: '#fff', height: '80vh', marginTop: 15 }} className="drp-card">
        <PAList data={pauthStore.comp1Data} onClick={(id) => { pauthActionStore.setSelected(id); }} />
      </div>
    </Col>
    <Col span={16}>
      <div style={{ backgroundColor: '#fff0', height: '80vh', marginTop: 15, marginLeft: 10 }}>
        <Row style={{ padding: 20 }} className="drp-card">
          <RxDetails data={pauthStore.comp2Data} />
          <Triangle
            style={{
              position: "absolute",
              top: "25%",
              left: 0,
            }}
          />
          {/* <GroupComponent /> */}
        </Row>
      </div>
    </Col>
  </Row></>;
});
export default TaskPriorAuths;