const Triangle = ({ style }) => {
  return (
    <div
      style={{
        height: 30,
        width: 30,
        transform: "rotate(45deg)",
        backgroundColor: "white",
        ...style,
      }}
    ></div>
  );
};
export default Triangle;
