
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import PtItem from './PtItem';
import { dbstore } from '../dataLayer/stores/dbStore';
const PtList = observer(({ data, onClick = () => { } }) => {
  console.log(data)
  if (!data) {
    data = {
      arr: [
        { visitedDays: "blah", pname: "blah", page: "blah", pgender: "blah" },
        { visitedDays: "blah", pname: "blah", page: "blah", pgender: "blah" },
        { visitedDays: "blah", pname: "blah", page: "blah", pgender: "blah" }
      ]
    };
  }

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;
    // console.log('scrolling', e.target.clientHeight, e.target.scrollHeight - e.target.scrollTop)

    if (bottom) {
      console.log('bottom reached')
      dbstore.getpatientDetails(true, skip + 1)
      setskip(prev => { return prev + 1 })
    }
  }
  const [skip, setskip] = useState(0)



  return <>
    <div onScroll={handleScroll} style={{ overflowY: 'scroll', paddingRight: 5, height: "80vh" }}>
      {data.arr.map((item, index) => {
        // console.log(index, item._id)
        return <Row ><PtItem data={item} onClick={onClick} /></Row>;
      })}</div>
  </>;
});
export default PtList;
