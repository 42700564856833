
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import RecentPatientItem from './RecentPatientItem';
const RecentPatients = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = {
      arr: [
        {
          date: "08 JUNE 2021",
          time: "7:25PM",
          avatarText: "SV",
          name: "PEter parker",
          age: 34,
          gender: "Male"
        },
        {
          date: "08 JUNE 2021",
          time: "7:25PM",
          avatarText: "SV",
          name: "PEter parker",
          age: 34,
          gender: "Male"
        }, {
          date: "08 JUNE 2021",
          time: "7:25PM",
          avatarText: "SV",
          name: "PEter parker",
          age: 34,
          gender: "Male"
        }
      ]
    };
  }
  return <Row style={{ padding: 20 }} className="drp-card">
    <h6 style={{ fontWeight: '400' }}>RECENT PATIENTS</h6>
    {data.arr.map((item, index) => {
      return <RecentPatientItem data={item} onClick={onClick} />
    })}
  </Row>;
});
export default RecentPatients;
