import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import PersonalInfo from "../components/PersonalInfo";
import ProfileChip from "../components/ProfileChip";
import { MdEmail } from "react-icons/md";
import { FaMapMarkedAlt, FaPhoneAlt } from "react-icons/fa";
import { observer } from "mobx-react";

const ProfilePersonalDetails = observer(({ data = null, uploadProfilePic = () => { } }) => {
  if (!data) {
    data = {
      name: 'blah',
      avatar: 'blah',
      age: 33,
      gender: 'Male',
      email: "faefho@oof.eg",
      phone: '523592230953',
      address: ''
    }
  }
  return (
    <>
      <div
        // className={"col-12 col-lg-4 mr-lg-4"}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          borderRadius: 30,
          // boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        }}
      >

        <Row gutter={(8, 8)} align="middle">
          <Col span={24}>
            {/* {console.log(props)} */}
            <ProfileChip
              name={data.name}
              avatar={data.avatar}
              supportingText={data.age + ", " + data.gender ? data.gender : ''}
              allowEdit
              entity="user"
              onEdit={uploadProfilePic}
            />
          </Col>
          <Col span={24}>
            <PersonalInfo
              FIcon={MdEmail}
              textContent={data.email}
              iconSize={20}
            />
          </Col>
          <Col span={24}>
            <PersonalInfo
              FIcon={FaPhoneAlt}
              textContent={data.phone}
              iconSize={20}
            />
          </Col>
          <Col span={24}>
            <PersonalInfo
              FIcon={FaMapMarkedAlt}
              textContent={data.address}
              iconSize={20}
            />
          </Col>
        </Row>

      </div>
    </>
  );
});
export default ProfilePersonalDetails;
