import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Doughnut, Bar } from "react-chartjs-2";

const VitalChart = observer(({ data }) => {
  const rand = () => Math.round(Math.random() * 20 - 10);
  var randoms = () => [...Array(12)].map(() => Math.floor(Math.random() * 20));
  var reminderlist = [];
  var takenlist = [];
  if (!data) {
    data = {
      name: "Blood Pressure",
      labels: randoms(),
      data: randoms(),
    };
  }

  const graphdata = {
    labels: data.labels,
    datasets: [
      {
        label: data.name,
        data: data.data,
        borderColor: data.color ? data.color : "#FFFFFF",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          var gradient = ctx.createLinearGradient(0, 0, 0, 220);
          gradient.addColorStop(0, data.color ? data.color : "#FFFFFF" + "FF");
          gradient.addColorStop(1, data.color ? data.color + "00" : "#FFFFFF" + "00");
          return gradient;
        },
        fill: true,
        pointBackgroundColor: data.color ? data.color : "#FFFFFF",
        pointRadius: 3,
        type: "line",
        order: 1,
        tension: 0.5,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
    },
    //responsive: true,

    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        grid: { lineWidth: 0.2 },
      },
    },
    maintainAspectRation: false,
  };

  return (
    <Bar
      data={graphdata}
      // width={100}
      height={150}
      options={options}
    />
  );
});
export default VitalChart;
