/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const TrackRefillInfo = ({ data, goBack = () => { } }) => {
  if (!data) {
    data = { rxname: "blah", rxno: "blah", docname: "blah" }
  }
  return (
    <svg width={"auto"} height={173.294} viewBox="0 0 927.5 173.294">
      <defs>
        <style>
          {
            "._TaskRefillInfoa{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}._TaskRefillInfob{font-size:24px;}._TaskRefillInfob,._TaskRefillInfoe{font-family:Nunito-Bold, Nunito;font-weight:700;}._TaskRefillInfoc{fill:#f4f6f9;}._TaskRefillInfod{fill:#272d5c;font-family:Nunito-Regular, Nunito;}._TaskRefillInfod,._TaskRefillInfoe{font-size:18px;}._TaskRefillInfoe,._TaskRefillInfof{fill:#fa644e;}"
          }
        </style>
      </defs>
      <g transform="translate(5801.5 2987.187)">
        <g transform="translate(-5805 -2984.187)" cursor="pointer" onClick={goBack}>
          <line className="_TaskRefillInfoa" x1={16} transform="translate(5 13)" />
          <path className="_TaskRefillInfoa" d="M13,21,5,13l8-8" />
        </g>
        <text className="_TaskRefillInfob" transform="translate(-5707 -2963.187)">
          <tspan x={-61.128} y={0}>
            {"Track Refill"}
          </tspan>
        </text>
        <g transform="translate(-6696 -3120.187)">
          <rect
            className="_TaskRefillInfoc"
            width={429.679}
            height={85.295}
            rx={15}
            transform="translate(896 221)"
          />
          <text className="_TaskRefillInfod" transform="translate(1017 253.143)">
            <tspan x={-88.533} y={0} xmlSpace="preserve">
              {`${data.rxname}   -  ${data.rxno}`}
            </tspan>
          </text>
          <text className="_TaskRefillInfoe" transform="translate(994 284.443)">
            <tspan x={-65.52} y={0}>
              {`Dr. ${data.docname}`}
            </tspan>
          </text>
        </g>
        <g transform="translate(-4898 -2977.187)">
          <g transform="translate(0)">
            <path
              className="_TaskRefillInfof"
              d="M26.333,19.1a1.4,1.4,0,0,1,.19-.7,1.943,1.943,0,0,1,.121-.179,9.348,9.348,0,0,0,1.592-5.21A9.814,9.814,0,0,0,18.216,3.375a9.952,9.952,0,0,0-9.825,7.667,9.271,9.271,0,0,0-.213,1.973,9.786,9.786,0,0,0,9.865,9.756,11.881,11.881,0,0,0,2.723-.444c.652-.179,1.3-.415,1.465-.479a1.525,1.525,0,0,1,.537-.1,1.5,1.5,0,0,1,.583.115l3.271,1.16a.78.78,0,0,0,.225.058.459.459,0,0,0,.462-.462.741.741,0,0,0-.029-.156Z"
              transform="translate(-4.237 -3.375)"
            />
            <path
              className="_TaskRefillInfof"
              d="M18.981,24.96c-.208.058-.473.121-.762.185a10.648,10.648,0,0,1-1.962.26,9.786,9.786,0,0,1-9.865-9.756,10.907,10.907,0,0,1,.087-1.235c.035-.248.075-.5.133-.738.058-.26.127-.519.2-.773l-.462.41a8.586,8.586,0,0,0-2.977,6.456A8.49,8.49,0,0,0,4.806,24.5c.133.2.208.358.185.462S4.3,28.537,4.3,28.537a.463.463,0,0,0,.156.444.47.47,0,0,0,.294.1.414.414,0,0,0,.167-.035l3.237-1.275a.9.9,0,0,1,.692.012,9.71,9.71,0,0,0,3.5.692A9.061,9.061,0,0,0,19.281,25.3s.185-.254.4-.554C19.465,24.822,19.223,24.9,18.981,24.96Z"
              transform="translate(-3.375 -5.085)"
            />
          </g>
        </g>
        <g transform="translate(-5111.711 -2977.187)">
          <g transform="translate(0)">
            <path
              className="_TaskRefillInfof"
              d="M26.333,19.1a1.4,1.4,0,0,1,.19-.7,1.943,1.943,0,0,1,.121-.179,9.348,9.348,0,0,0,1.592-5.21A9.814,9.814,0,0,0,18.216,3.375a9.952,9.952,0,0,0-9.825,7.667,9.271,9.271,0,0,0-.213,1.973,9.786,9.786,0,0,0,9.865,9.756,11.881,11.881,0,0,0,2.723-.444c.652-.179,1.3-.415,1.465-.479a1.525,1.525,0,0,1,.537-.1,1.5,1.5,0,0,1,.583.115l3.271,1.16a.78.78,0,0,0,.225.058.459.459,0,0,0,.462-.462.741.741,0,0,0-.029-.156Z"
              transform="translate(-4.237 -3.375)"
            />
            <path
              className="_TaskRefillInfof"
              d="M18.981,24.96c-.208.058-.473.121-.762.185a10.648,10.648,0,0,1-1.962.26,9.786,9.786,0,0,1-9.865-9.756,10.907,10.907,0,0,1,.087-1.235c.035-.248.075-.5.133-.738.058-.26.127-.519.2-.773l-.462.41a8.586,8.586,0,0,0-2.977,6.456A8.49,8.49,0,0,0,4.806,24.5c.133.2.208.358.185.462S4.3,28.537,4.3,28.537a.463.463,0,0,0,.156.444.47.47,0,0,0,.294.1.414.414,0,0,0,.167-.035l3.237-1.275a.9.9,0,0,1,.692.012,9.71,9.71,0,0,0,3.5.692A9.061,9.061,0,0,0,19.281,25.3s.185-.254.4-.554C19.465,24.822,19.223,24.9,18.981,24.96Z"
              transform="translate(-3.375 -5.085)"
            />
          </g>
        </g>
        <text className="_TaskRefillInfoe" transform="translate(-4973.289 -2959.187)">
          <tspan x={-63.135} y={0}>
            {"Contact Doctor"}
          </tspan>
        </text>
        <text className="_TaskRefillInfoe" transform="translate(-5188 -2959.187)">
          <tspan x={-64.467} y={0}>
            {"Contact Patient"}
          </tspan>
        </text>
      </g>
    </svg>
  )
};

export default TrackRefillInfo;
