import React from "react";
import complianceBubbleAnim from "../assets/animations/complianceBubbleAnimation.json";
import Lottie from "react-lottie";
import { Colours } from "../Colours";
import { round } from "lodash";
import { observer } from "mobx-react";
const ComplianceBubble = observer(
  ({ store, percentage = 46, compScale = 1, showPercentage = true, marginLeft = 0 }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: complianceBubbleAnim,
    };
    // console.log({ compScale, percentage });
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontFamily: "Nunito-Bold",
          fontSize: 47 * compScale,
          color: Colours.primaryText,
          marginLeft: marginLeft

          // transform: `scale(${(compScale, compScale)})`,
          // minHeight: "25vh",
        }}
      >
        <p
          className="text-center"
          style={{
            position: "absolute",
            top: "10%",
            color: Colours.primaryText,
          }}
        >
          {showPercentage && `${round(percentage, 2)}%`}
        </p>
        <Lottie
          options={{ ...defaultOptions }}
          height={`${30 * compScale}vh`}
          width={`${30 * compScale}vh`}
        />
      </div>
    );
  }
);

export default ComplianceBubble;
