
import { Col, Row, Layout, Carousel } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import VitalChart from './VitalChart';
import { patientReportsStore } from '../dataLayer/stores/pages/patientReportsStore';
import { dbstore } from '../dataLayer/stores/dbStore';
const VitalChartCarousel = observer(({ data }) => {
  useEffect(() => {
    dbstore.getpatientReports();
  }, [])
  if (!data) {
    data = {};
  }
  var settings = {
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1

  };
  return <>

    <Carousel {...settings} dots={true} draggable={true} >
      <Row>
        <Col span={24}>
          <Row >
            <Col span={11} className='drp-card'>
              <VitalChart data={patientReportsStore.comp2Data.bloodPressure || null} />
            </Col>
            <Col span={11} offset={1} className='drp-card'>
              <VitalChart data={patientReportsStore.comp2Data.bloodGlucose || null} />
            </Col>

          </Row>

        </Col>
        <Col style={{ height: 40 }}>

        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row >
            <Col span={11} className='drp-card'>
              <VitalChart data={patientReportsStore.comp2Data.temperature || null} />
            </Col>
            <Col span={11} offset={1} className='drp-card'>
              <VitalChart data={patientReportsStore.comp2Data.weight || null} />
            </Col>

          </Row>

        </Col>
        <Col style={{ height: 40 }}>

        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row >
            <Col span={11} className='drp-card'>
              <VitalChart data={patientReportsStore.comp2Data.oxygenLevels || null} />
            </Col>


          </Row>

        </Col>
        <Col style={{ height: 40 }}>

        </Col>
      </Row>



    </Carousel>
  </>;
});
export default VitalChartCarousel;
