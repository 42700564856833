
import Item from "antd/lib/list/Item";
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import moment from "moment";
import { vitalsActionsStore } from "../actions/vitalsActionsStore";
import { dbstore } from "../dbStore";

export default class Store {
  vitalMetadata = {
    bloodPressure: {
      name: "Blood Pressure",
      units: "cmHg",
      color: "#FF6773"
    },
    bloodGlucose: {
      name: "Blood Glucose",
      units: "mgdl",
      color: '#7066D1'
    },
    temperature: {
      name: "Temperature",
      units: "F",
      color: '#0C82BA'
    },
    weight: {
      name: "Weight",
      units: "lbs",
      color: '#574FFA'
    },
    oxygenLevels: {
      name: "Oxygen Levels",
      units: "mmHg",
      color: '#ED8F03'
    },
  };
  comp1 = null
  comp2 = {}
  comp3 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    var obj = null
    console.log('outside', dbstore.patientReports)
    if (dbstore.patientReports) {
      console.log('inside')
      if (dbstore.patientReports.vitalsAvg) {
        obj = {}
        console.log(dbstore.patientReports.vitalsAvg)
        dbstore.patientReports.vitalsAvg.forEach(item => {
          item.name = item.key;

          obj[item.name] = item;

        });
      }
    }
    if (obj) {
      if (this.comp1 == null) {
        this.comp1 = {}
      }
      Object.assign(this.comp1, obj)
    } else {
      this.comp1 = null
    }

    return this.comp1
  }
  get comp2Data() {
    var obj = {};
    if (!dbstore.patientReports) { return }
    var vitalmonthly = _.groupBy(dbstore.patientReports.vitalsMonthly, "type");

    if (vitalmonthly) {
      vitalmonthly = vitalmonthly[vitalsActionsStore.comp1.selected];
    }
    var data = [];
    var labels = [];
    for (var i = 0; i < vitalmonthly?.length || 0; i++) {
      var d = vitalmonthly[i]["value"]
        ? vitalmonthly[i]["value"]
        : vitalmonthly[i]["systolic"];
      data.push(d);
      labels.push(moment(vitalmonthly[i]["month"], "MM").format("MMM"));
    }
    obj = {
      name: this.vitalMetadata[vitalsActionsStore.comp1.selected].name,
      unit: this.vitalMetadata[vitalsActionsStore.comp1.selected].units,
      color: this.vitalMetadata[vitalsActionsStore.comp1.selected].color,

      data: data.reverse(),
      labels: labels.reverse(),
    };
    console.log(obj);
    Object.assign(this.comp2, obj);

    return this.comp2;
  }
  get comp3Data() {
    if (!dbstore.patientVitalRecords) {
      return
    }
    var obj = {};
    var values = [];
    dbstore.patientVitalRecords.forEach((vr) => {
      values.push(vr["vitals"]);
    });
    obj = {
      key: vitalsActionsStore.comp1.selected,
      values: values,
    };

    Object.assign(this.comp3, obj);

    return this.comp3
  }
}

const vitalsStore = new Store();
export { vitalsStore };

