/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const TimelogItem = ({ data }) => {
  if (!data) {
    data = { time: "blah", checkin: "blah", checkout: "blah", date: "blah" }
  }
  return (
    <svg width={902.709} height={133} viewBox="0 0 902.709 133">
      <defs>
        <style>
          {
            "._TimelogItema,._TimelogIteme,._TimelogItemh{fill:none;}._TimelogItema{stroke:rgba(224,228,235,0.61);stroke-miterlimit:10;}._TimelogItema,._TimelogIteme{stroke-width:3px;}._TimelogItemb{fill:#f4f6f9;}._TimelogItemc{fill:#6c7b8a;font-size:20px;font-family:Nunito-Regular, Nunito;}._TimelogItemd{fill:#140f26;font-size:25px;}._TimelogItemd,._TimelogItemf{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._TimelogIteme{stroke:#000;stroke-linecap:round;stroke-linejoin:round;}._TimelogItemf{fill:#666565;font-size:18px;letter-spacing:-0.018em;}._TimelogItemg{stroke:none;}"
          }
        </style>
      </defs>
      <g transform="translate(5832.651 -2058)">
        <g transform="translate(-5832.651 2058)">
          <g className="_TimelogItema">
            <rect className="_TimelogItemg" width={902.709} height={133} rx={15} />
            <rect
              className="_TimelogItemh"
              x={1.5}
              y={1.5}
              width={899.709}
              height={130}
              rx={13.5}
            />
          </g>
          <rect className="_TimelogItemb" width={902.709} height={133} rx={15} />
          <text className="_TimelogItemc" transform="translate(95 68)">
            <tspan x={0} y={20}>
              {`${data.time}`}
            </tspan>
          </text>
          <text className="_TimelogItemd" transform="translate(95 56)">
            <tspan x={0} y={0} xmlSpace="preserve">
              {`${data.checkin}  - ${data.checkout}`}
            </tspan>
          </text>
          <g transform="translate(31.8 51.5)">
            <path
              className="_TimelogIteme"
              d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
              transform="translate(-3 -3)"
            />
            <path className="_TimelogIteme" d="M18,9v9l6,3" transform="translate(-3 -3)" />
          </g>
        </g>
        <text className="_TimelogItemf" transform="translate(-5024.441 2162)">
          <tspan x={-29.759} y={0}>
            {`${data.date}`}
          </tspan>
        </text>
      </g>
    </svg>
  )
};

export default TimelogItem;
