import { inject, observer } from "mobx-react";
import React from "react";
import { MdLock } from "react-icons/md";
import { Colours } from "../Colours";
import { Row, Col } from "antd";
const PersonalInfo = observer(({ style, textContent, FIcon, iconSize }) => {
  const styles = {
    container: {
      //flexDirection: "row",
      backgroundColor: "#f5f5f5",
      alignItems: "center",
      //justifyContent: "space-between",
      borderRadius: 30,
      marginTop: 10,
      marginBottom: 10,
      minHeight: 45,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 25
    },
    fIcon: {
      textAlign: "center",
    },
    info: {
      flexWrap: "wrap",
      fontFamily: "Nunito-Regular",
      fontSize: 19,
      color: Colours.primaryText,
    },
    lockIcon: {
      textAlign: "center",
      color: "grey",
    },
  };
  return (
    <Row
      className="row mx-0"
      style={{
        ...styles.container,
        ...style,
        wordWrap: "break-word",
        wordBreak: "break-all",
      }}
    >
      <Col span={4}>
        <FIcon className="mx-2 ml-3" size={iconSize} style={styles.fIcon} />
      </Col>
      <Col span={16}>
        <div className="col" style={styles.info}>
          {textContent}
        </div>
      </Col>
      <Col span={4} >
        <MdLock

          name={"lock"}
          size={iconSize}
          style={styles.lockIcon}
        />
      </Col>
    </Row>
  );
});

export default PersonalInfo;
