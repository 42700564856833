
import { Col, Row, Layout, Empty } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import UploadImage from './UploadImage'
const UploadList = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = {
      arr: [
        { url: null, active: true },

      ]
    };
  }
  return <Row gutter={[16, 16]}>
    {data.arr.length == 0 && <Empty style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '25vh' }} />}
    {data.arr.map((item, index) => {
      return <>
        <Col span={12}>
          <UploadImage data={item} onClick={onClick} />
        </Col>
      </>;
    })}
  </Row>;
});
export default UploadList;
