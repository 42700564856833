
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
  toJS,
  runInAction,
} from "mobx";
import moment from "moment";
import Cookies from 'js-cookie'
import Client from 'twilio-chat';
import jwt_decode from "jwt-decode";
import HelpStore from "./pages/help"; 
import _ from "lodash";
const fetch = require("node-fetch");
//const endpoint = "https://api.doctorspharmacy.us";
const endpoint = process.env.REACT_APP_SERVER_URL
export default class dbStore {
  endpoint = endpoint;
  loading = true;
  login = false;
  // userid = "611014b01aee40109052b07e";
  decodedUserId = null//"60dcc4dcfef5466308c7b3dd"// null;
  userid = "t1881t";
  selectedphid = null//"60aaa04957d4da36ba5494b9"
  selectedpatid = null//"60ab4e7a574c694b60994106"
  reportDate = moment().startOf('day').format('x')
  technicianDetails = null;

  pharmacyReports = null;

  rxDetails = null;

  patientDetails = null;
  patientChatDetails = null;
  technicianReports = null;

  prescriptionTransfers = null;

  sessions = null;

  patientReports = null;

  patientVitalRecords = null;

  dailyPc = null;
  chatToken = null;
  chatClient = null;
  chatChannels = [];
  patientFilter = {};
  filteredRx = []
  refillrequests = []
  priorAuths = []
  refillsDue = 0
  doctorReports = {}
  constructor() {
    makeAutoObservable(this, {
      setPatientFilter: action
    });
  }


  *getData(refresh = false) {


    this.refreshStore()
    if (!this.decodedUserId) {
      return
    }

    yield this.gettechnicianDetails(refresh)

    yield this.getrxDetails(refresh)
    yield this.getpatientDetails(refresh)
    yield this.getpatientMessageDetails(refresh)

    // yield this.getprescriptionTransfers(true)
    yield this.getsessions(true)
    // yield this.getpatientReports(refresh)
    // yield this.getpatientVitalRecords('bloodPressure', 10, 1)
    yield this.getdailyPc(refresh)
    yield this.getpharmacyReports(refresh)
    // yield this.clockin()
    // yield this.clockout()
    // console.log(toJS(this))
    yield this.gettechnicianReports(this.reportDate)
    yield this.getReportStats(refresh);



    // yield this.createChannel({ clientId: '60dcca167d76f664ec812c9b', clientType: 'patients' })
    // yield this.getChatToken(false)
    // yield this.getChannels();

  }


  *gettechnicianDetails(refresh = false) {
    this.checkSession()
    this.loading = true;
    var path = '/technicians';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?_id=${this.decodedUserId}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.selectedphid = res[0]?.phid || null
    this.technicianDetails = res[0];
    this.loading = false;
  }

  *getReportStats(refresh = false) {
    this.checkSession()

    this.loading = true
    var path = '/technicians/reportstats';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?phid=${this.selectedphid}`,
      options,

    );
    res = yield res.json();
    console.log(res, 'report stats -------------------------------------------------');
    // this.contacts = res
    this.refillsDue = res.refillsDueNextWeek
    this.refillrequests = res.refillRequests
    this.priorAuths = res.priorAuth

    this.loading = false;
  }
  *getpharmacyReports(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/pharmacy/reports';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?_id=${this.selectedphid}`,
      options,

    );
    res = yield res.json();
    console.log(res, 'pharmacy reports');
    this.pharmacyReports = res[0];
    this.loading = false;
  }


  *getrxDetails(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/pharmacy/rx';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      // options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?phid=${this.selectedphid}`,
      options,

    );
    res = yield res.json();
    console.log(res, 'recent rxes');
    this.rxDetails = res.rxes;
    this.loading = false;
  }


  *filterRxDetails() {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/pharmacy/rx';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }

    options['cache'] = "no-cache"

    var res = yield fetch(
      `${endpoint}${path}?phid=${this.selectedphid}&patid=${this.selectedpatid}`,
      options,

    );
    res = yield res.json();

    this.loading = false;
    this.filteredRx = res.rxes;
  }



  setPatientFilter(key, val) {
    if (val != '') {
      this.patientFilter[key] = val
    } else {
      delete this.patientFilter[key]
    }
  }
  *getpatientDetails(refresh = false, skip = 0, newFilter = false) {
    this.checkSession()

    this.loading = true;
    var limit = 10
    var path = '/technicians/pharmacy/patients';
    var filterPath = '';

    for (var key in this.patientFilter) {
      if (this.patientFilter[key] != '') {
        filterPath += '&' + key + '=' + this.patientFilter[key];
      }
    }

    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?phid=${this.selectedphid}&limit=${limit}&skip=${skip * limit}${filterPath}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    res = res.patients
    if (res.length > 0) {
      this.setSelectedPatientId(res[0]._id)
    }
    if (this.patientDetails) {
      if (newFilter) {
        this.patientDetails = res;

      } else {

        this.patientDetails.push(...res);

      }
    } else {
      this.patientDetails = res;

    }
    this.loading = false;
  }

  patientChatFilter = {}
  setPatientChatFilter(key, val) {
    if (val != '') {
      this.patientChatFilter[key] = val
    } else {
      delete this.patientChatFilter[key]
    }
  }
  *getpatientMessageDetails(refresh = false, skip = 0, newFilter = false) {
    this.checkSession()

    this.loading = true;
    var limit = 10
    var path = '/technicians/pharmacy/patients';
    var filterPath = '';

    for (var key in this.patientChatFilter) {
      if (this.patientChatFilter[key] != '') {
        filterPath += '&' + key + '=' + this.patientChatFilter[key];
      }
    }

    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?phid=${this.selectedphid}&limit=${limit}&skip=${skip * limit}${filterPath}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    res = res.patients
    if (res.length > 0) {
      this.setSelectedPatientId(res[0]._id)
    }
    if (this.patientChatDetails) {
      if (newFilter) {
        this.patientChatDetails = res;

      } else {

        this.patientChatDetails.push(...res);

      }
    } else {
      this.patientChatDetails = res;

    }
    this.loading = false;
  }

  *getDoctorReports(fromDate) {
    this.checkSession()

    this.loading = true
    var path = '/technicians/doctorreports';

    var from = moment(Number(fromDate)).utc().startOf('day')
    var to = moment(Number(fromDate)).utc().endOf('day')

    console.log(from, to, fromDate, 'fron to')
    // return
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }

    options['cache'] = "no-cache"
    console.log(`${endpoint}${path}?tecid=${this.decodedUserId}&from=${from.toISOString()}&to=${to.toISOString()}`)

    var res = yield fetch(
      `${endpoint}${path}?tecid=${this.decodedUserId}&from=${from.toISOString()}&to=${to.toISOString()}`,
      options,

    );
    res = yield res.json();
    console.log(res, 'doctor reports -------------------------------------------------');
    this.doctorReports = res
    this.loading = false;
  }
  *gettechnicianReports(fromDate, refresh = false) {
    this.checkSession()

    this.loading = true;
    fromDate = moment(fromDate, 'x').startOf('day').format('x')
    var toDate = moment(fromDate, "x").endOf('day').format('x');
    console.log(fromDate, toDate)
    var path = '/technicians/reports';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?tcnid=${this.userid}&taskDate={"fromDate":${fromDate},"toDate":${toDate}}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.technicianReports = res;
    this.loading = false;
  }


  *getprescriptionTransfers(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/pharmacy/prescriptiontransfers';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?`,
      // `${endpoint}${path}?phid=${this.selectedphid}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.prescriptionTransfers = res;
    this.loading = false;
  }


  *getsessions(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/sessions';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?tcnid=${this.userid}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.sessions = res;
    this.loading = false;
  }


  *getpatientReports(refresh = true) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/patient/reports';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?patid=${this.selectedpatid}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    if (res.length > 0) {
      this.patientReports = res[0];
    } else {
      console.log('setting as null')
      this.patientReports = null
    }
    this.loading = false;
  }


  *getpatientVitalRecords(type, limit, page, refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/patient/vitalrecords';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?patid=${this.selectedpatid}&type=${type}&limit=${limit}&page=${page}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.patientVitalRecords = res;
    this.loading = false;
  }


  *getdailyPc(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/pillcompliance/daily';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}?phid=${this.selectedphid}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.dailyPc = res;
    this.loading = false;
  }


  *startRR(obj) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/refillrequests';
    var raw = {
      ...obj
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "put",
        // cache: "no-cache",
        credentials: 'include',
        // headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    this.loading = false;
    return status;
  }

  *updatePtr(obj) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/prescriptiontransfers';
    var raw = {
      data: obj,
      condition: { _id: obj.id }
    }
    delete raw.data.id
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "put",
        cache: "no-cache",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    this.loading = false;
    return status;
  }

  *updatePatientDetails(obj) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/patients';
    var raw = {
      data: obj,
      condition: { _id: obj.id }
    }
    console.log(raw)
    delete raw.data._id
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "put",
        cache: "no-cache",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    // yield this.getpatientDetails(true)

    var index = _.findIndex(this.patientDetails, { _id: obj.id })

    for (var key in obj) {
      if (key != 'id') {
        this.patientDetails[index][key] = obj[key]
      }
    }
    this.loading = false;
    return status;
  }

  *clockin() {
    this.checkSession()
    this.loading = true;
    var path = '/technicians/sessions';
    var raw = {
      userid: this.userid
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "post",
        // cache: "no-cache",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    res = yield res.json();
    console.log(res);
    yield this.getsessions(true)
    this.loading = false;
    return status;
  }

  *clockout() {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/sessions';
    var raw = {
      userid: this.userid
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "put",
        // cache: "no-cache",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    res = yield res.json();
    console.log(res);
    yield this.getsessions(true)
    this.loading = false;
    return status;
  }
  *getChatToken(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/chat/token';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.chatToken = res.jwt;
    console.log(this.chatToken)
    this.chatClient = yield Client.create(this.chatToken);
    this.loading = false;
  }
  *getChannels(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/chat/channel';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.chatChannels = res;
    yield this.joinChannel();
    yield this.loadChannelMessages();
    // console.log(this.chatChannels)
    this.loading = false;
  }
  configureChannelEvents(channel, ind) {
    channel.on('messageAdded', message => {
      this.chatChannels[ind].messages.items.push(message);
    });
    channel.on('memberJoined', member => {
      console.log('member joined');
      // store.domainStore.addMessage({body: `${member.identity} has joined the channel.`});
    });
    channel.on('memberLeft', member => {
      // store.domainStore.addMessage({body: `${member.identity} has left the channel.`});
    });
  }
  *joinChannel(arr) {
    if (!arr) {
      arr = this.chatChannels;
    }

    for (var i = 0; i < arr.length; i++) {
      let channel = yield this.chatClient.getChannelBySid(arr[i]._id);
      channel =
        channel.channelState.status !== 'joined' ? yield channel.join() : channel;
      this.chatChannels[i].channel = channel
      this.configureChannelEvents(channel, i);
    }


  }
  *loadChannelMessages() {


    for (let i = 0; i < this.chatChannels.length; i++) {
      var m = yield this.chatChannels[i].channel.getMessages();
      // console.log(m, this.chatChannels[i]._id, 'messages')
      this.chatChannels[i].messages = m;
    }
  }

  *createChannel(obj) {
    this.checkSession()

    this.loading = true;
    var path = '/chat/channel'
    var raw = {

      ...obj
    };
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "post",
        // cache: "no-cache",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    this.loading = false;
    return status;

  }

  *pcBulkUpdate(obj) {
    this.loading = true;
    this.checkSession()

    var path = '/technicians/pillcompliance/bulk';
    var raw = {
      ...obj
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "put",
        // cache: "no-cache",
        credentials: 'include',
        // headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    this.loading = false;
    return status;
  }

  *updatetasks(obj) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/tasks';
    var raw = {
      data: obj,
      condition: { _id: obj._id }
    }
    console.log(raw)
    delete raw.data.id
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "put",
        cache: "no-cache",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    this.loading = false;
    return status;
  }


  *newProfilePic(file) {
    this.checkSession()

    this.loading = true;
    var path = '/technicians/profilepic';
    var raw = { userid: this.userid }
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "post",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    res = yield res.json();
    console.log(res);
    if (status == 200) {
      var url = res.url

      var s = yield this.uploadFile(url, file)

      console.log(s)
      status = s
    }

    this.loading = false;
    return status;
  }



  *uploadFile(url, file) {
    this.checkSession()

    this.loading = true;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/jpeg");
    var res = yield fetch(url, {
      method: "PUT",
      body: file,
      headers: myHeaders,
      redirect: "follow",
    })

    var status = res.status;
    this.loading = false;

    return status;
  }

  *loginFunc(phone, otp) {

    var raw = {
      phone: phone,
      otp: otp
    }
    var res = yield fetch(
      `${endpoint}/auth/technicians/login`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: 'include',
        body: JSON.stringify(raw)
      }
    );

    var status = res.status;
    res = yield res.json();
    if (status == 200) {
      this.login = true;

      yield this.getData(true)

    }
    return status;
  }

  *requestOTP(phone) {

    var raw = {
      phone: phone
    }
    var res = yield fetch(
      `${endpoint}/auth/technicians/otp`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: 'include',
        body: JSON.stringify(raw)
      }
    );

    var status = res.status;
    res = yield res.json();
    console.log(res, status);
    if (status == 200) {
      this.loading = false;
    }
    return status;
  }

  *logoutFunc() {
    this.loading = true;
    var res = yield fetch(
      `${endpoint}/auth/technicians/logout`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: 'include',

      }
    );

    var status = res.status;
    res = yield res.json();
    // console.log(res, status);
    if (status == 200) {
      this.loading = false
      this.login = false;
      this.resetStore()
    }
    return status;
  }


  setSelectedPatientId(id) {

    this.selectedpatid = id;
    // this.filterRxDetails()
  }
  setReportsDate(date) {
    this.reportDate = moment(date, 'x').startOf('day').format('x')
    console.log(this.reportDate)
  }

  refreshStore() {


    this.login = Cookies.get('tlogin') == 'true'
    this.decodedUserId = Cookies.get('ttoken') ? jwt_decode(Cookies.get('ttoken'))._id : null
    console.log(this.decodedUserId, Cookies.get('ttoken'), 'decoded')
    // console.log(this.login)
  }

  resetStore() {
    console.log("reset store called:::::::::");
    HelpStore.setFirebaseDoc(HelpStore.db, "status_listener", this.decodedUserId, {
      isLoggedIn: false
    });
    //this.loading = true;
    this.login = false;
    this.userid = "t1881t";
    this.selectedphid = null//"60aaa04957d4da36ba5494b9"
    this.selectedpatid = null//"60ab4e7a574c694b60994106"
    this.reportDate = moment().startOf('day').format('x')
    this.technicianDetails = null;

    this.pharmacyReports = null;

    this.rxDetails = null;

    this.patientDetails = null;

    this.technicianReports = null;

    this.prescriptionTransfers = null;

    this.sessions = null;

    this.patientReports = null;

    this.patientVitalRecords = null;

    this.dailyPc = null;
  }

  checkSession() {
    this.login = Cookies.get('tlogin') == 'true'
    if (this.login === false) {
      this.resetStore();
    }
  }
}
const dbstore = new dbStore();
export { dbstore };

