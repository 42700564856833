
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import TaskItem from './TaskItem';
const TaskList = observer(({ data, onChange = () => { } }) => {
  if (!data) {
    data = {
      arr: [
        {
          description: 'blah',
          imageResponse: false
        },
        {
          description: 'blah',
          imageResponse: true
        },
        {
          description: 'blah',
          imageResponse: true
        }

      ]
    };
  }
  return <>
    {data.arr.map((item, index) => {
      return <>
        <TaskItem data={item} onChange={onChange} />
      </>;
    })}
  </>;
});
export default TaskList;
