
import { Col, Row, Layout, DatePicker, Button, message } from 'antd'
import { useEffect, useState } from 'react'
import ReportDetails from '../ReportDetails';
import Triangle from '../Triangle';
import moment from 'moment'
import { dbstore } from '../../dataLayer/stores/dbStore';
import TaskList from "../TaskList";
import { reportsStore } from '../../dataLayer/stores/pages/reportsStore';
import GroupComponent from '../GroupComponent';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Reports = ({ props }) => {
  const [date, setdate] = useState(moment())
  const generateReports = () => {
    reportsStore.selectedDate = moment(date, 'x').format('x');
    dbstore.getDoctorReports(moment(date, 'x').format('x'))
  }
  useEffect(() => { generateReports() }, [])
  return <Row gutter={[16, 16]} style={{ width: '100%' }}>
    <Col span={8}>
      <div style={{ backgroundColor: '#fff', height: '80vh', marginTop: 15 }} className="drp-card">
        <p style={{ fontSize: 20, fontFamily: 'Nunito' }}>Select Date</p>
        <DatePicker placeholder="dd/mm/yyy" defaultValue={moment()} style={{ borderRadius: 20, height: 45, width: '100%' }}
          onChange={(e) => { console.log(e); setdate(e) }} />
        <Button className="drp-btn-green" style={{ marginTop: 20 }} onClick={() => {
          generateReports()
        }}>
          Generate Report
        </Button>
      </div>
    </Col>
    <Col span={16}>
      <div style={{ backgroundColor: '#fff', height: '80vh', marginTop: 15 }} className="drp-card">
        <ReportDetails data={reportsStore.comp1Data} />
        {/* <GroupComponent /> */}
        {/* <div style={{ marginTop: 0, padding: 20, height: 270, overflowY: 'scroll' }}>
          <TaskList data={reportsStore.comp2Data}
            onChange={async (obj) => {
              console.log(obj)
              var res = await dbstore.updatetasks(obj)
              if (res == 200) {
                message.success("Update Successfull")
              } else {
                message.error('Oops! something went wrong')
              }
            }}
          />
        </div> */}
        <Triangle
          style={{
            position: "absolute",
            top: "25%",
            left: 0,
          }}
        />
      </div>
    </Col>
  </Row>;
};
export default Reports;