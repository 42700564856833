
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
  toJS,
} from "mobx";
import moment from "moment";
import { dbstore } from "../dbStore";
import { vitalsStore } from "./vitalsStore";

export default class Store {

  comp1 = {}
  comp2 = {}
  selectedPeriod = "Yearly"
  constructor() {
    makeAutoObservable(this, {
      setSelectedPeriod: action
    });
  }
  getChartData(type) {
    var obj = {};
    if (!dbstore.patientReports) { return {} }
    var vitalmonthly = _.groupBy(dbstore.patientReports.vitalsMonthly, "type");

    if (vitalmonthly) {
      vitalmonthly = vitalmonthly[type];
    }
    var data = [];
    var labels = [];
    for (var i = 0; i < vitalmonthly.length; i++) {
      var d = vitalmonthly[i]["value"]
        ? vitalmonthly[i]["value"]
        : vitalmonthly[i]["systolic"];
      data.push(d);
      labels.push(moment(vitalmonthly[i]["month"], "MM").format("MMM"));
    }
    obj = {
      name: vitalsStore.vitalMetadata[type].name,
      unit: vitalsStore.vitalMetadata[type].units,
      data: data.reverse(),
      labels: labels.reverse(),
    };
    return obj;
  }
  get comp1Data() {
    var period = "Daily"
    var val = 0;
    var datalist = [{
      missed: 0,
      missedPercent: 0,
      taken: 0,
      takenPercent: 0,
      total: 0,
      unanswered: 0,
      unansweredPercent: 0
    }]
    if (dbstore.patientReports) {
      datalist = []
      period = this.selectedPeriod
      // console.log(dbstore.reports.last6Avg)
      switch (period) {
        case "Daily":
          datalist = dbstore?.patientReports?.last6Avg.days
          val = dbstore?.patientReports?.pillcomplianceAvg?.day?.takenPercent * 100
          break;
        case "Weekly":
          datalist = dbstore?.patientReports?.last6Avg.weeks
          val = dbstore?.patientReports?.pillcomplianceAvg?.week?.takenPercent * 100
          break
        case "Monthly":
          datalist = dbstore?.patientReports?.last6Avg.months
          val = dbstore?.patientReports?.pillcomplianceAvg?.month?.takenPercent * 100
          break
        case "Yearly":
          datalist = dbstore?.patientReports?.last6Avg.years
          val = dbstore?.patientReports?.pillcomplianceAvg?.year?.takenPercent * 100
          break
        default:
          datalist = dbstore?.patientReports?.last6Avg.days
          val = dbstore?.patientReports?.pillcomplianceAvg?.day?.takenPercent * 100
      }
    }
    console.log(toJS(datalist))
    var obj = {
      graphData: datalist, timePeriod: period, percentage: val,
      onChange: (val) => {

        // this.selectedPeriod = val
        this.setSelectedPeriod(val)
      }
    }
    Object.assign(this.comp1, obj);

    return this.comp1
  }
  get comp2Data() {
    var obj = {};
    if (!dbstore.patientReports) { return {} }
    obj = {
      'bloodPressure': this.getChartData('bloodPressure'),
      'bloodGlucose': this.getChartData('bloodGlucose'),
      'temperature': this.getChartData('temperature'),
      'oxygenLevels': this.getChartData('oxygenLevels'),
      'weight': this.getChartData('weight'),

    }
    Object.assign(this.comp2, obj);
    return this.comp2
  }

  setSelectedPeriod(val) {
    this.selectedPeriod = val;
    // console.log('well i did change ', val)
  }
}

const patientReportsStore = new Store();
export { patientReportsStore };

