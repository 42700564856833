
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import ComplianceCard from "../ComplianceCard";
import ComplianceChartCard from "../ComplianceChartCard";
import SmallInfoCard from "../SmallInfoCard";
// import RowComponent from "../RowComponent";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import RecentPatients from "../RecentPatients";


import RecentRx from "../RecentRx";
import { observer } from 'mobx-react';
import { homeStore } from '../../dataLayer/stores/pages/homeStore';
import { dbstore } from '../../dataLayer/stores/dbStore';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Home = observer(({ props }) => {
  let history = useHistory();
  return <><Row gutter={[8, 8]}>

    <Col span={24}>
      <Row>
        <p
          className="m-2"
          style={{
            fontFamily: "Nunito-SemiBold",
            fontSize: "28px",
            color: "#272D5C",
          }}
        >
          Overview
        </p>
      </Row>
      <Row>
        <Col span={8}>
          <div style={box}>
            <ComplianceCard
              data={homeStore.comp1Data}
            />
          </div>
        </Col>
        <Col span={16}>
          <div style={box}>
            <ComplianceChartCard
              data={
                homeStore.comp2Data
                // {
                //   // graphData: homeStore.comp2Data.graphdata,
                //   graphData: [],
                //   timePeriod: 30,
                // }
              }
            />
          </div>
        </Col>
      </Row>
    </Col>

    <Col span={8}>
      <div style={box}>
        <SmallInfoCard onClick={() => { history.push('taskRefillRequests') }} data={{ info: "You have pending", highlight: "Refill Requests", count: homeStore.comp3Data, buttonText: "Complete Now" }} />
      </div>
    </Col>
    <Col span={8}>
      <div style={box}>
        <SmallInfoCard onClick={() => { history.push('taskPriorAuths') }} data={{ info: "You have pending", highlight: "Prior-Auths", count: homeStore.comp4Data, buttonText: "Complete Now" }} />
      </div>
    </Col>
    <Col span={8} >
      <div style={{ height: '85%', ...box }}>
        <SmallInfoCard onClick={() => { history.push('reports') }} data={{ info: "Daily Reports", highlight: " ", buttonText: "View Now", count: 0 }} />
      </div>
    </Col>
    <Col span={12}>
      <div style={box}>
        <RecentPatients data={homeStore.comp6Data} onClick={(id) => {
          console.log(id)
          dbstore.setSelectedPatientId(id);


          history.push('patients')
        }} />
      </div>
    </Col>
    <Col span={12}>
      <div style={box}>
        <RecentRx data={homeStore.comp7Data} onClick={(patid) => {
          console.log(patid)
          // dbstore.setSelectedPatientId(patid); 

          // history.push('patients') 

        }} />
      </div>
    </Col>
  </Row></>;
});
export default Home;