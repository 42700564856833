
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { dbstore } from "../dbStore";
import moment from 'moment'
export default class Store {

  comp1 = {}
  comp2 = {}
  selectedDate = null
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (!this.selectedDate) {
      return
    }
    var obj = {
      pts: 3, date: moment(this.selectedDate, 'x').format("DD-MM-YY"), day: ' ' + moment(this.selectedDate, 'x').format("ddd"),
      pv: 3, rc: 1, pc: 1, pf: 2
    }
    Object.assign(this.comp1, obj)
    return this.comp1
  }
  get comp2Data() {
    var obj = {
      arr: []
    }
    if (!dbstore.technicianReports) {
      return
    }
    var tasks = dbstore.technicianReports;
    tasks.forEach(item => {
      obj.arr.push({
        description: item.description,
        imageResponse: item.imageResponse,
        id: item._id,
        status: item.status,
        taskDate: item.taskDate
      })
    });
    Object.assign(this.comp2, obj)
    return this.comp2
  }
}

const reportsStore = new Store();
export { reportsStore };

