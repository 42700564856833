import { Button, TextField } from "@material-ui/core";
import { Button as Button1, message } from 'antd'
import { useRef, useState } from "react";
import { Image, Modal, Spinner, } from "react-bootstrap";
import loginLeft from "../../assets/images/login-left.png";
import loginLogo from "../../assets/images/login-logo@2x.png";
import { Colours } from "../../Colours";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Row, Col } from 'antd'
import { dbstore } from "../../dataLayer/stores/dbStore";
// import CONSTANTS from "../Constants";

const Login = ({ store }) => {
  const [showLogin, setShowLogin] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phone, setPhone] = useState('');
  const [otp, setOTP] = useState('');
  const toggleShowLogin = () => {
    setShowLogin(!showLogin);
  };

  const toggleShowOTP = () => {
    setShowOTP(!showOTP);
  };
  return (
    <>
      {/* <div ng-hide="show" className="spinner spinner-grow  ml-auto"></div> */}
      <Row align="middle" justify="center" style={{ height: '100vh' }}>
        <Col span={12}>
          <div className="p-0 " style={{ overflow: "hidden", height: '100vh' }}>
            <Image
              alt=""
              src={loginLeft}
              height={"100%"}
              width={"100%"}
              fluid
              className="h-100"
              style={{ objectFit: "fill" }}
            />
          </div></Col>
        <Col span={12}>
          <div className=" p-0 d-flex flex-column align-items-center justify-content-start">
            <div className="d-flex justify-content-end align-self-center w-75 h-25">
              <Image
                alt=""
                className="mt-auto"
                src={loginLogo}
                height={"100%"}
                width={"100%"}
                fluid
              />
            </div>
            {showLogin ? (
              <form

                className="mt-5 w-75 w-lg-50"
                onSubmit={() => {
                  // alert("form submitted");
                }}
              >
                <p
                  className=""
                  style={{
                    fontFamily: "SourceSansPro-Regular",
                    fontSize: 19,
                    color: Colours.secondaryText,
                    textAlign: "center",
                  }}
                >
                  Welcome back! Please login to your account.
                </p>
                {!showOTP ? (
                  <div>
                    <div className="py-3 w-75 ml-auto" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <TextField
                        disabled={loading}
                        className="w-100"
                        id="username"
                        label="Phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                      />
                    </div>
                    <div className="py-3 w-75 ml-auto" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <div className="w-100 form-row justify-content-between align-items-center">
                        <Button
                          disabled={loading}
                          variant="contained"
                          disableElevation
                          size="large"
                          style={{
                            backgroundColor: Colours.primaryText,
                            color: "white",
                          }}
                          onClick={() => {
                            if (phone == '') {
                              message.error("Please enter Phone Number")
                              return
                            }
                            setLoading(true);
                            requestOTP({
                              phone: phone,
                              onSuccess: async (response) => {
                                setShowOTP(true);
                                setLoading(false);
                              },
                              onFailure: () => {
                                message.error("wrong Phone no passed or not found")
                                setLoading(false);
                              },
                            });
                          }}
                        >
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Continue
                        </Button>
                        <div style={{ display: 'inline-block', float: 'right', marginTop: 0 }}>
                          <Button
                            disabled={loading}
                            variant="outlined"
                            size="large"
                            onClick={toggleShowLogin}
                          >
                            Get Invited
                          </Button></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="py-3 w-75 ml-auto" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <TextField
                        disabled={loading}
                        className="w-100"
                        id="otp"
                        label="OTP"
                        value={otp}
                        onChange={(e) => { setOTP(e.target.value) }}
                      />
                    </div>
                    <div className="py-3 w-75 ml-auto" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <div className="w-100 form-row justify-content-between align-items-center">
                        <Button
                          disabled={loading}
                          variant="contained"
                          disableElevation
                          size="large"
                          style={{
                            backgroundColor: Colours.primaryText,
                            color: "white",
                          }}
                          onClick={() => {
                            setLoading(true);
                            login({
                              phone: phone,
                              otp: otp,
                              onSuccess: async (response) => {
                                await store.successfulSignIn(response);
                                setLoading(false);
                              },
                              onFailure: () => {
                                message.error("invalid OTP")
                                setLoading(false);
                              },
                            });
                          }}
                        >
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Continue
                        </Button>
                        <div style={{ display: 'inline-block', float: 'right', right: 0, marginTop: 0, }}>
                          <Button
                            variant="outlined"
                            size="large"
                            onClick={() => {
                              toggleShowOTP();
                              setOTP('');
                            }}
                          >
                            <IoIosArrowRoundBack size={25} /> Back
                          </Button></div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            ) : (
              <form

                className="mt-5 pt-5 w-75 w-lg-50"
                onSubmit={() => {
                  alert("form submitted");
                }}
              >
                <p
                  className=""
                  style={{
                    fontFamily: "SourceSansPro-Regular",
                    fontSize: 19,
                    color: Colours.secondaryText,
                    textAlign: "center",
                  }}
                >
                  Begin Your Journey with us!
                </p>
                <div className="w-75 ml-auto py-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <TextField className="w-100" id="username" label="Phone Number" />
                </div>
                <div className="py-3 w-75 " style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <div className="w-100 form-row justify-content-between align-items-center">
                    <Button
                      variant="contained"
                      disableElevation
                      size="large"
                      style={{
                        backgroundColor: Colours.primaryText,
                        color: "white",
                      }}
                    >
                      Get Invited
                    </Button>
                    <div style={{ display: 'inline-block', float: 'right', right: 0, marginTop: 0, }}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={toggleShowLogin}
                      >
                        <IoIosArrowRoundBack size={25} /> Back
                      </Button></div>
                  </div>
                </div>
              </form>
            )}
            <a
              href="#"
              style={{
                position: "fixed",
                bottom: 20,
                fontFamily: "SourceSansPro-Regular",
                fontSize: 11,
                color: Colours.secondaryText,
                textAlign: "center",
              }}
            >
              Terms of Use. Privacy Policy
            </a>
          </div>
        </Col>
      </Row>
      <VerticallyCenteredModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

const login = async ({ phone, otp, onSuccess, onFailure }) => {
  phone = validate(phone);
  try {
    var res = await dbstore.loginFunc(phone, otp)

    if (res == 200) {
      message.success("Welcome Back")
    } else {

      onFailure();
    }
  } catch (error) {
    console.log(error);
  }
};

const requestOTP = async ({ phone, onSuccess, onFailure }) => {
  phone = validate(phone);
  try {
    var res = await dbstore.requestOTP(phone)

    if (res == 200) {
      message.success("OTP sent success");
      await onSuccess(res);
    } else {

      onFailure();
    }
  } catch (error) {
    console.log(error);
  }
};

function validate(phone) {
  if (phone.toString().charAt(0) != '+') {
    phone = '+1' + phone;
  }
  return phone;
}

function VerticallyCenteredModal({ currentCompliance, onHide, ...otherProps }) {
  return (
    <Modal
      {...otherProps}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      contentClassName="px-5 py-4"
      className="border-radius-30"
    >
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Failure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Failed</div>
        </Modal.Body>
      </>
    </Modal>
  );
}

export default Login;
