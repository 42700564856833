
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { dbstore } from "../dbStore";
import { createBrowserHistory } from 'history'
import moment from 'moment'
import HelpStore from "./help";
import { useHistory } from "react-router-dom";


export default class Store {
  history = null
  setHistory = (history) => {
    this.history = history
  }
  comp1 = {}
  comp2 = {}
  comp3 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (!dbstore.patientDetails) {
      return
    }
    var obj = { arr: [] };
    dbstore.patientDetails.forEach(item => {
      var o = {
        visitedDays: moment().diff(parseInt(item.lastVisited), 'days'), pname: item.name, page: moment().diff(item.dob, 'years'), pgender: item.gender, _id: item._id
      }
      obj.arr.push(o)
    });
    console.log(obj)
    Object.assign(this.comp1, obj)
    return this.comp1
  }
  get comp2Data() {
    if (!dbstore.patientDetails) {
      return
    }
    var obj = {
      ptinfo: {},
      ptrxlist: []
    }

    var pti = _.find(dbstore.patientDetails, { _id: dbstore.selectedpatid });
    console.log(pti)
    if (dbstore.patientDetails.length == 0) {
      Object.assign(this.comp2, obj)
      return this.comp2

    }
    obj.ptinfo = {
      visitedDays: 12, pname: pti.name, page: 35, pgender: pti.gender,
      paddress: Object.values(pti.address).join(','), pemail: pti.email, pphone: pti.phone, alt: pti.name.substr(0, 2).toUpperCase(),
      onchat1: async () => {
        // var temp = _.filter(dbstore.chatChannels, function (ch) {
        //   return ch.hostId == pti._id || ch.clientId == pti._id
        // });

        // if (temp.length > 0) {
        //   console.log('channel exists')
        //   return 200;

        // } else {
        //   console.log('creating new channel')
        //   var status = await dbstore.createChannel({ clientId: pti._id, clientType: 'patients' })
        //   if (status == 200) {
        //     await dbstore.getChannels(true);
        //   }
        //   return status;

        // }


        await HelpStore.setCurrentUserName(pti.name)
        await HelpStore.setCurrentUser(pti._id)
        await HelpStore.setCurrentUserType('patient')

        // this.history.push('/help')


      }
    }
    obj.compliance = pti.compliance;

    var rxarr = dbstore.filteredRx//_.filter(dbstore.rxDetails, { patid: dbstore.selectedpatid });

    rxarr.forEach(rx => {
      console.log(rx.statuscode)
      var o = {
        completedRefill: _.filter(rx.refills, { status: 'completed' }).length, id: rx._id,
        rxname: rx.name, rxtype: rx.statuscode == 13 ? 'rr' : rx.transactionstatuscode == 2 ? 'pa' : 'normal',

      }
      obj.ptrxlist.push(o)
    });
    console.log(obj)
    Object.assign(this.comp2, obj)
    return this.comp2
  }

  get comp3Data() {
    if (!dbstore.patientDetails) {
      return
    }
    var pti = _.find(dbstore.patientDetails, { _id: dbstore.selectedpatid });
    if (dbstore.patientDetails.length == 0) {
      Object.assign(this.comp2, {})
      return this.comp2

    }
    var obj = {
      email: pti.email,
      phone: pti.phone,
      address: pti.address,
      id: pti._id
    }
    Object.assign(this.comp3, obj)
    return this.comp3
  }
}

const patientsStore = new Store();
export { patientsStore };

