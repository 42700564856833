import "./App.css";
import SideNav from "./components/nav/SideNav";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Row, Col, Layout, Affix, Spin } from "antd";
import { observer } from "mobx-react-lite";
import TopNav from './components/nav/TopNav'
import RouteSwitcher from "./components/nav/routeSwitcher";
import { useWindowSize } from "./hooks/CustomHooks";
import { dbstore } from "./dataLayer/stores/dbStore";
import { LoadingOutlined } from "@ant-design/icons";
import Login from "./components/routes/Login";
// import { store } from "./stores/store";
// import "antd/dist/antd.css";
import GetTheApps from "./components/GetTheApps";
import { patientsStore } from "./dataLayer/stores/pages/patientsStore";
import { useEffect } from "react";
import HelpStore from "./dataLayer/stores/pages/help";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



const { Header, Content, Footer, Sider } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
dbstore.getData()
const App = observer(() => {
  const [width, height] = useWindowSize();
  var history = useHistory();

  patientsStore.setHistory(history)
  // useEffect(() => {
  //   const listener = HelpStore.listener();
  //   return () => {
  //     listener()
  //   }
  // }, [])
  return (
    <>
      {width <= 1270 ? (
        <GetTheApps />
      ) : <Router basename="/technicians">
        {!dbstore.login && <Login />}
        {dbstore.login && <Layout style={{ minHeight: "100vh" }}>
          <Row>
            <Col span={4}>
              <Affix>
                <SideNav />
              </Affix>
            </Col>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <Affix>
                    <TopNav />
                  </Affix>
                </Col>
              </Row>
              <Spin
                tip="Loading..."
                spinning={dbstore.loading}
                // spinning={true}

                indicator={antIcon}
              >
                <Row style={{
                  padding: 50,
                  paddingTop: 20,
                  paddingRight: 70,
                  paddingLeft: 70,
                }}>
                  <RouteSwitcher />
                </Row></Spin>

            </Col>
          </Row>
        </Layout>}
      </Router>
      }
    </>
  );
});
export default App;
