/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import { observer } from "mobx-react";
import React from "react";

const RRItem = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = { refills: "blah", dname: "blah", pname: "blah" }
  }
  return (
    <svg width={430.975} height={85.552} viewBox="0 0 430.975 85.552" style={{ cursor: 'pointer' }} onClick={() => { onClick(data.id) }}>
      <defs>
        <style>
          {
            "._RRItema,._RRItemd{fill:#fff;}._RRItema{stroke:#707070;}._RRItemb,._RRItemr{fill:none;}._RRItemb{stroke:rgba(224,228,235,0.61);stroke-miterlimit:10;stroke-width:3px;}._RRItemc{fill:#f4f6f9;}._RRIteme{fill:rgba(255,255,255,0);}._RRItemf{fill:url(#a);}._RRItemg{clip-path:url(#b);}._RRItemh{fill:url(#c);}._RRItemi{fill:url(#e);}._RRItemj{opacity:0.25;fill:url(#f);}._RRItemk,._RRIteml{opacity:0.2;}._RRItemk{fill:url(#g);}._RRItemm{fill:#6c7b8a;font-size:20px;font-family:Nunito-Regular, Nunito;}._RRItemn{fill:#140f26;font-size:15px;}._RRItemn,._RRItemo{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._RRItemo,._RRItemp{fill:#4f73e0;}._RRItemo{font-size:9px;}._RRItemq{stroke:none;}"
          }
        </style>
        <linearGradient
          id="a"
          x1={0.125}
          y1={-0.865}
          x2={0.718}
          y2={1.268}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#2dc9eb" />
          <stop offset={1} stopColor="#14d2b8" />
        </linearGradient>
        <clipPath id="b">
          <circle
            className="_RRItema"
            cx={21.5}
            cy={21.5}
            r={21.5}
            transform="translate(-0.22 -0.121)"
          />
        </clipPath>
        <radialGradient
          id="c"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ff8181" />
          <stop offset={1} stopColor="#b72323" />
        </radialGradient>
        <linearGradient
          id="e"
          x1={0.462}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#4a7ae8" />
          <stop offset={1} stopColor="#263088" />
        </linearGradient>
        <radialGradient
          id="f"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#a6b2ee" />
          <stop offset={0.611} stopColor="#8f97fe" />
          <stop offset={1} stopColor="#221e85" />
        </radialGradient>
        <radialGradient
          id="g"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#86b3c7" stopOpacity={0.533} />
        </radialGradient>
      </defs>
      <g transform="translate(-2077.625 -3356.5)">
        <g transform="translate(0 125)">
          <g transform="translate(2077.625 3231.5)">
            <g className="_RRItemb">
              <rect className="_RRItemq" width={430.975} height={85.552} rx={15} />
              <rect
                className="_RRItemr"
                x={1.5}
                y={1.5}
                width={427.975}
                height={82.552}
                rx={13.5}
              />
            </g>
            <rect className="_RRItemc" width={430.975} height={85.552} rx={15} />
            <g transform="translate(12.865 9.649)">
              <rect className="_RRItemd" width={57.892} height={66.254} rx={15} />
              <rect className="_RRItemd" width={57.892} height={66.254} rx={15} />
              <g transform="translate(46.314 5.146)">
                <rect
                  className="_RRIteme"
                  width={4}
                  height={5}
                  rx={2}
                  transform="translate(1.197 0.752)"
                />
                <rect
                  className="_RRItemf"
                  width={4}
                  height={5}
                  rx={2}
                  transform="translate(1.197 0.752)"
                />
              </g>
              <g transform="translate(5.457 13.504)">
                <g className="_RRItemg" transform="translate(2.274 1.516)">
                  <path
                    className="_RRItemh"
                    d="M420.981,511.716s10.107-5.016,19.435-1.3,9.024,6.523,16.492,5.349,10.238-6.7,21.495-6.437,12.68,7.476,23.533,7.476,19.879-7.476,19.879-7.476v21.247H425.16Z"
                    transform="translate(-406.207 -486.648)"
                  />
                </g>
                <g className="_RRItemg" transform="translate(2.274 1.516)">
                  <path
                    className="_RRItemi"
                    d="M464.961,512.5s5.929-5.311,15.257-1.592,9.024,6.523,16.492,5.349,10.238-6.7,21.495-6.437,12.68,7.476,23.533,7.476,19.879-7.476,19.879-7.476v21.247H464.961Z"
                    transform="translate(-465.52 -487.134)"
                  />
                </g>
                <ellipse
                  className="_RRItemj"
                  cx={24}
                  cy={23.5}
                  rx={24}
                  ry={23.5}
                  transform="translate(0.054 0.395)"
                />
                <path
                  className="_RRItemk"
                  d="M2186.575,343.961s14.84,10.3,13.406,24.4-17.329,19.312-17.329,19.312,22.11,1.142,22.839-19.627S2186.575,343.961,2186.575,343.961Z"
                  transform="translate(-2159.742 -342.244)"
                />
                <g className="_RRIteml" transform="translate(0.614 5.636) rotate(-8)">
                  <path
                    className="_RRItemd"
                    d="M.412,2.549A26.407,26.407,0,0,1,8.58.008c2.395-.143,2.13,1.742,1.412,1.97S4.177,3.791,2.55,4.347-.811,3.295.412,2.549Z"
                    transform="translate(6.572 2.003) rotate(-11)"
                  />
                  <path
                    className="_RRItemd"
                    d="M0,4.628C.011,3.564,1.551.948,1.927.43A1.035,1.035,0,0,1,3.24.117c.273.18.88.482.551,1.957A9.769,9.769,0,0,1,1.927,6.018C1.1,7-.011,5.692,0,4.628Z"
                    transform="translate(1.432 6.896) rotate(13)"
                  />
                </g>
              </g>
            </g>
            <text className="_RRItemm" transform="translate(93.271 43.741)">
              <tspan x={0} y={20}>
                {`${data.refills} Refills Completed So far`}
              </tspan>
            </text>
            <text className="_RRItemn" transform="translate(93.271 34.941)">
              <tspan x={0} y={0}>
                {`${data.dname} for ${data.pname}`}
              </tspan>
            </text>
          </g>
        </g>
        <text className="_RRItemo" transform="translate(2481.608 3380)">
          <tspan x={-5.629} y={0}>
            {"RF"}
          </tspan>
        </text>
        <path
          className="_RRItemp"
          d="M4.8.563A4.24,4.24,0,1,0,9.042,4.8,4.239,4.239,0,0,0,4.8.563Zm.976,5.985-1.508-1.1a.206.206,0,0,1-.084-.166V2.409A.206.206,0,0,1,4.392,2.2h.821a.206.206,0,0,1,.205.205V4.763l1.086.79a.205.205,0,0,1,.044.287L6.066,6.5A.207.207,0,0,1,5.779,6.548Z"
          transform="translate(2462.347 3371.87)"
        />
      </g>
    </svg>
  )
});

export default RRItem;
