
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
const T18Badge = observer(({ count = 1, showZero = false, style }) => {

  return <div style={{
    visibility: count > 0 || showZero ? 'show' : 'hidden',
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: 'red',
    color: 'white',
    position: 'absolute',
    right: 15,
    top: 5,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,

    // alignItems: 'center'
    ...style

  }}>
    <p>{count}</p>
  </div>;
});
export default T18Badge;
