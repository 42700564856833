
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import VitalInfoCard from './VitalInfoCard';
import { toJS } from 'mobx';
const VitalCardGroup = observer(({ data = null, onClick = () => { } }) => {
  console.log(toJS(data), "sfdggfs")
  if (!data) {
    data = {
      bloodPressure: {
        name: "Blood Pressure",
        systolic: 0,
        diastolic: 0,
        units: "cmHg",
      },
      bloodGlucose: {
        name: "Blood Glucose",
        value: 0,
        units: "mgdl",
      },
      temperature: {
        name: "Temperature",
        value: 0,
        units: "F",
      },
      weight: {
        name: "Weight",
        value: 0,
        units: "lbs",
      },
      oxygenLevels: {
        name: "Oxygen Levels",
        value: 0,
        units: "mmHg",
      },
    };
  }
  return <>
    <Row justify="space-between">
      <VitalInfoCard vitalKey="bloodPressure" data={{ vitalsAverage: { bloodPressure: data.bloodPressure } }} onClick={onClick} />
      <VitalInfoCard vitalKey="bloodGlucose" data={{ vitalsAverage: { bloodGlucose: data.bloodGlucose } }} onClick={onClick} />
      <VitalInfoCard vitalKey="temperature" data={{ vitalsAverage: { temperature: data.temperature } }} onClick={onClick} />
      <VitalInfoCard vitalKey="weight" data={{ vitalsAverage: { weight: data.weight } }} onClick={onClick} />
      <VitalInfoCard vitalKey="oxygenLevels" data={{ vitalsAverage: { oxygenLevels: data.oxygenLevels } }} onClick={onClick} />

    </Row>
  </>;
});
export default VitalCardGroup;
