
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";

export default class Store {

  comp1 = { selected: null }
  comp2 = {}
  constructor() {
    makeAutoObservable(this);
  }

  setSelected(id) {
    this.comp1.selected = id;
  }
}

const refillRequestActionStore = new Store();
export { refillRequestActionStore };

