
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { refillRequestActionStore } from '../../dataLayer/stores/actions/refillRequestActionStore';
import { refillRequestStore } from '../../dataLayer/stores/pages/refillRequestStore';
import RRList from '../RRList';
import RxDetails from '../RxDetails';
import TrackRefill from '../TrackRefill';
import Triangle from '../Triangle';
import { observer } from 'mobx-react'
import { dbstore } from '../../dataLayer/stores/dbStore';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const TaskRefillRequests = observer(({ props }) => {
  useEffect(() => {
    dbstore.getReportStats()

  }, [])
  const [showrefills, setshowrefills] = useState(false)
  return <>
    <Row gutter={[8, 8]}>
      <Col span={8}>
        <div style={{ backgroundColor: '#fff', height: '80vh', marginTop: 15 }} className="drp-card">
          <RRList data={refillRequestStore.comp1Data} onClick={(id) => { refillRequestActionStore.setSelected(id); }} />
        </div>
      </Col>
      <Col span={16}>
        <div style={box}>
          <Row style={{ padding: 20, height: '80vh', marginTop: 15 }} className="drp-card">
            {/* {showrefills && <TrackRefill goBack={() => { setshowrefills(false) }} />} */}
            {!showrefills && <RxDetails data={refillRequestStore.comp2Data} showRefill={() => {
              //  setshowrefills(true)
            }} />}

            <Triangle
              style={{
                position: "absolute",
                top: "25%",
                left: 0,
              }}
            />
            {/* <GroupComponent /> */}
          </Row>
        </div>
      </Col>
    </Row>
  </>;
});
export default TaskRefillRequests;