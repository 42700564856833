
import { Col, Row, Layout } from 'antd'
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react'
import { vitalsActionsStore } from '../../dataLayer/stores/actions/vitalsActionsStore';
import { dbstore } from '../../dataLayer/stores/dbStore';
import { vitalDetailsStore } from '../../dataLayer/stores/pages/vitalDetailsStore';
import { vitalsStore } from '../../dataLayer/stores/pages/vitalsStore';
import VitalChart from '../VitalChart';
import VitalHeader from '../VitalHeader';
import VitalRecords from '../VitalRecords';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const PatientVitalDetails = observer(({ props }) => {
  useEffect(() => {
    console.log('called')
    dbstore.getpatientReports();
    dbstore.getpatientVitalRecords(vitalsActionsStore.comp1.selected, 10, 0)

  }, [])
  return <>
    <Row gutter={[8, 8]} style={{ width: '100%' }}>
      <Col span={24}>
        <div style={box}>
          <VitalHeader data={vitalDetailsStore.comp1Data} />
        </div>
      </Col>
      <Col span={14}>
        <div style={box}>
          <Row style={{ padding: 20 }} className="drp-card">
            <VitalChart data={vitalDetailsStore.comp2Data} />
          </Row>
        </div>
      </Col>
      <Col span={10}>
        <div style={box}>
          <VitalRecords limit={12} data={vitalDetailsStore.comp3Data} />
        </div>
      </Col>
    </Row>
  </>;
});
export default PatientVitalDetails;