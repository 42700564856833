import React, { useEffect, useRef } from "react";
// import { Chart } from "chart.js";
import { inject, observer } from "mobx-react";
import { Doughnut, Bar } from "react-chartjs-2";

// import { getMixedBarGraphData } from "../Common.fns";
const ComplianceChart = observer(({ data, height = null }) => {
  // const rand = () => Math.round(Math.random() * 20 - 10);
  // var randoms = () => [...Array(30)].map(() => Math.floor(Math.random() * 20));
  // var randoms =[1,2,3]
  var datalist = data.graphData
  var period = data.timePeriod
  var reminderlist = []
  var takenlist = []
  var labels = []
  // console.log(datalist)
  for(var i=0;i<period;i++){
    if(i<datalist.length){
      reminderlist.push(datalist[i].total)
      takenlist.push(datalist[i].taken)
    }
    else{
      reminderlist.push(0)
      takenlist.push(0)
    }
    
    labels.push(i)
  }

  const graphdata = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Pills Prescribed",
        data: reminderlist,
        borderWidth: 1,
        radius: 5,
        order: 2,
      },
      {
        label: "Compliance",
        data: takenlist,
        borderColor: "#A3A0FB",
        backgroundColor: "#A3A0FB",
        fill: false,
        pointBackgroundColor: "#A3A0FB",
        pointRadius: 3,
        type: "line",
        order: 1,
        tension: 0.5,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    //responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRation: false,
  };

  return (
    <Bar
      data={graphdata}
      // width={100}
      height={height ? height : 100}
    //options={options}
    />
  );
});

export default ComplianceChart;
