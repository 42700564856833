/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
const TimeCard = ({ data }) => {
  let history = useHistory();
  if (!data) {
    data = { time: "blah", type: "blah" }
  }
  return (
    <svg viewBox="0 0 227 265">
      <defs>
        <style>
          {
            "._TimeCarda,._TimeCardf{fill:#fff;}._TimeCarda{stroke:#707070;}._TimeCardb,._TimeCardc,._TimeCardd,._TimeCarde{fill:#272d5c;}._TimeCardb{font-size:20px;font-family:Nunito-Light, Nunito;font-weight:300;}._TimeCardc{font-size:14px;font-family:Nunito-Regular, Nunito;}._TimeCarde{font-size:28px;}._TimeCarde,._TimeCardf{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._TimeCardf{font-size:18px;}._TimeCardg{stroke:none;}._TimeCardh{fill:none;}"
          }
        </style>
      </defs>
      <g transform="translate(-9 -577)">
        <g className="_TimeCarda" transform="translate(9 577)">
          <rect className="_TimeCardg" width={227} height={265} rx={18} />
          <rect
            className="_TimeCardh"
            x={0.5}
            y={0.5}
            width={226}
            height={264}
            rx={17.5}
          />
        </g>
        <text className="_TimeCardb" transform="translate(123 628)">
          <tspan x={-68.57} y={0}>
            {"Your work clock"}
          </tspan>
        </text>
        <text className="_TimeCardc" transform="translate(123 785)" cursor="pointer" onClick={() => { history.push("/profile") }}>
          <tspan x={-55.692} y={0}>
            {"View Full Timelog"}
          </tspan>
        </text>

        <text className="_TimeCarde" transform="translate(123 670)">
          <tspan x={-37.516} y={0}>
            {`${data.time}`}
          </tspan>
        </text>

        <g cursor="pointer" onClick={() => data.onclick()}>
          <path
            className="_TimeCardd"
            d="M21.261,0h97.951a21.261,21.261,0,0,1,0,42.521H21.261A21.261,21.261,0,0,1,21.261,0Z"
            transform="translate(52 709.5)"
          />

          <text className="_TimeCardf" transform="translate(123 737.045)">
            <tspan x={-36.7} y={0}>
              {`Clock-${data.type}`}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
};

export default TimeCard;
