/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React, { useState } from "react";
import { Button, message, Timeline, Empty, Image } from 'antd'
import { observer } from "mobx-react";
import moment from 'moment'
const UploadInfo = observer(({ data = null, onSubmit = () => { } }) => {
  if (false) {
    data = {
      status: 'Data Entry Pending',
      url: 'https://via.placeholder.com/300.png/09f/000',
      previousPharmacy: 'feapof',
      comments: [
        { date: 'blah', comment: 'blah blah' },
        { date: 'blah', comment: 'blah blah' },
        { date: 'blah', comment: 'blah blah' },
        { date: 'blah', comment: 'blah blah' },
      ]
    }
  }
  const [comment, setcomment] = useState('')
  const [showInfo, setshowInfo] = useState(true)
  const upArrow = "M13.7,13.944l5.682,5.923a1.04,1.04,0,0,0,1.517,0,1.162,1.162,0,0,0,0-1.584l-6.438-6.71a1.041,1.041,0,0,0-1.481-.033L6.5,18.278a1.159,1.159,0,0,0,0,1.584,1.04,1.04,0,0,0,1.517,0Z"
  const downArrow = "M13.7,17.5l5.682-5.923a1.04,1.04,0,0,1,1.517,0,1.161,1.161,0,0,1,0,1.584l-6.438,6.71a1.041,1.041,0,0,1-1.481.033L6.5,13.164a1.158,1.158,0,0,1,0-1.584,1.04,1.04,0,0,1,1.517,0Z"
  return (
    <>
      {!data && <Empty style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '25vh' }} />}
      {data && <div style={{ width: '100%', display: 'flex', alignItems: 'center', paddingRight: 30, paddingLeft: 30, flexDirection: 'column' }}>
        <Image src={data.url} style={{ width: 300, height: 300, objectFit: 'cover', marginTop: 30 }} />

        <div style={{ height: 30, backgroundColor: '#272D5C', width: '100%', borderRadius: 30, marginTop: 20 }}>
          <p style={{ color: "#fff", fontWeight: 'bold', paddingLeft: 30, fontSize: 20 }}>Info</p>
        </div>
        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginRight: 10 }}>Rx Number         </p>
            <input
              // type="number"
              value={data.newrxno}
              onChange={(e) => {
                console.log(e.target.value);
                data.newrxno = e.target.value
              }}
              placeholder="Label"
              style={{
                backgroundColor: '#F5F5F5', padding: 10, borderRadius: 30, border: 0,
                width: 400, height: '100%', fontSize: 18, marginRight: 20
              }}
            >

            </input>


          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginRight: 10 }}>Prescribed Doctor</p>
            <input

              value={data.prescribedDoctor}
              onChange={(e) => {
                console.log(e.target.value);
                data.prescribedDoctor = e.target.value
              }}
              placeholder="Label"
              style={{
                backgroundColor: '#F5F5F5', padding: 10, borderRadius: 30, border: 0,
                width: 400, height: '100%', fontSize: 18, marginRight: 20
              }}
            >

            </input>


          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginRight: 10 }}>Previous Pharmacy</p>
            <input

              value={data.previousPharmacy}
              onChange={(e) => {
                console.log(e.target.value);
                data.previousPharmacy = e.target.value
              }}
              placeholder="Label"
              style={{
                backgroundColor: '#F5F5F5', padding: 10, borderRadius: 30, border: 0,
                width: 400, height: '100%', fontSize: 18, marginRight: 20
              }}
            >

            </input>


          </div>

          <Button size='large' className="drp-btn-green"
            onClick={() => {

              onSubmit(data)
            }}
            style={{ width: 150, marginTop: 8, alignSelf: 'center' }}>Submit</Button>
        </div>

        <div style={{ height: 30, backgroundColor: '#A01027', width: '100%', borderRadius: 30, marginTop: 20 }}>
          <p style={{ color: "#fff", fontWeight: 'bold', paddingLeft: 30, fontSize: 20 }}>Comments History</p>

        </div>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
          <input

            onChange={(e) => {

              setcomment(e.target.value)
            }}
            placeholder="Label" style={{
              backgroundColor: '#F5F5F5', padding: 10, borderRadius: 30, border: 0,
              width: 400, height: '100%', fontSize: 18, marginRight: 20
            }}></input>

          <Button size='large' className="drp-btn-green"
            onClick={() => {
              if (comment == '') {
                message.warning("Nothing to post!")
                return
              }
              data.comments.push({ comment: comment, date: moment().format('x') });
              onSubmit(data)
            }}
            style={{ width: 80, marginTop: 8 }}>Post</Button>
        </div>
        <Timeline style={{ marginTop: 30 }}>
          {data.comments.map((item, index) => {
            return <Timeline.Item>
              <div style={{
                backgroundColor: '#F2F2F2', height: 75, width: 500, borderRadius: 15, flexDirection: 'column',
                display: 'flex', padding: 10, fontFamily: 'Nunito-Regular', color: '#43425D', fontSize: 16
              }}>
                <p style={{ flex: 5 }}>{item.comment}</p>
                <p style={{ flex: 1, fontSize: 12 }}>{moment(parseInt(item.date)).format("DD MMM YY")}</p>
              </div>
            </Timeline.Item>
          })}
        </Timeline>


      </div>
        // <svg width={'auto'} height={'auto'} viewBox="0 0 939 812">
        //   <defs>
        //     <style>
        //       {
        //         "._UploadInfoa{fill:#272d5c;}._UploadInfob{fill:#a01027;}._UploadInfoc,._UploadInfod,._UploadInfoj{fill:#fff;}._UploadInfoc{font-size:16px;font-family:Nunito-SemiBold, Nunito;font-weight:600;}._UploadInfoe,._UploadInfof,._UploadInfok{fill:#43425d;}._UploadInfoe,._UploadInfoj,._UploadInfok{font-size:20px;}._UploadInfoe,._UploadInfof,._UploadInfoj{font-family:Nunito-Bold, Nunito;font-weight:700;}._UploadInfof{font-size:19px;}._UploadInfog{fill:#f5f5f5;}._UploadInfoh{fill:#909090;font-size:17px;font-family:SourceSansPro-Regular, Source Sans Pro;}._UploadInfoi{fill:#23d098;}._UploadInfok{font-family:Nunito-Regular, Nunito;}._UploadInfol{fill:rgba(255,255,255,0);}._UploadInfom{fill:url(#ab);}"
        //       }
        //     </style>
        //     <pattern
        //       id="ab"
        //       preserveAspectRatio="xMidYMid slice"
        //       width="100%"
        //       height="100%"
        //       viewBox="0 0 500 500"
        //     >
        //       <image width={500} height={500} xlinkHref={data.url} />
        //     </pattern>
        //   </defs>
        //   <g transform="translate(6466 544)" >
        //     <g cursor='pointer' onClick={() => { setshowInfo(!showInfo) }}>
        //       <rect
        //         className="_UploadInfoa"
        //         width={915}
        //         height={34}
        //         rx={17}
        //         transform="translate(-6466 -146)"
        //       />
        //       <text className="_UploadInfoc" transform="translate(-6443 -124)">
        //         <tspan x={0} y={0}>
        //           {"Info"}
        //         </tspan>
        //       </text>
        //       <path
        //         className="_UploadInfod"
        //         d={showInfo ? upArrow : downArrow}
        //         transform="translate(-5599.838 -144.986)"
        //       />
        //     </g>


        //     <g transform={`translate(0 ${showInfo ? 0 : -300})`} >
        //       <g cursor='pointer' onClick={() => { setshowInfo(!showInfo) }}>
        //         <rect
        //           className="_UploadInfob"
        //           width={915}
        //           height={34}
        //           rx={17}
        //           transform="translate(-6466 234)"
        //         />
        //         <text className="_UploadInfoc" transform="translate(-6443 256)">
        //           <tspan x={0} y={0}>
        //             {"Comments History"}
        //           </tspan>
        //         </text>
        //         <path
        //           className="_UploadInfod"
        //           d={showInfo ? downArrow : upArrow}
        //           transform="translate(-5599.838 235.279)"
        //         />
        //       </g>

        //       <g transform="translate(0 0)">
        //         <rect
        //           className="_UploadInfog"
        //           width={615}
        //           height={54}
        //           rx={17}
        //           transform="translate(-6366 304)"
        //         />
        //         <foreignObject width={615}
        //           height={54} transform="translate(-6366 304)">
        //           <input

        //             onChange={(e) => {

        //               setcomment(e.target.value)
        //             }}
        //             placeholder="Label" style={{
        //               backgroundColor: 'transparent', padding: 10, border: 0,
        //               width: '100%', height: '100%', fontSize: 18
        //             }}></input>
        //         </foreignObject>

        //         <foreignObject width={200}
        //           height={54} transform="translate(-5731 304)">
        //           <Button size='large' className="drp-btn-green"
        //             onClick={() => {
        //               if (comment == '') {
        //                 message.warning("Nothing to post!")
        //                 return
        //               }
        //               data.comments.push({ comment: comment, date: moment().format('x') });
        //               onSubmit(data)
        //             }}
        //             style={{ width: 80, marginTop: 8 }}>Post</Button>
        //         </foreignObject>
        //         <foreignObject width={615}
        //           height={204} transform="translate(-6366 404)" style={{ overflowY: 'scroll' }}>
        //           <div >
        //             <Timeline style={{ marginTop: 10 }}>
        //               {data.comments.map((item, index) => {
        //                 return <Timeline.Item>
        //                   <div style={{
        //                     backgroundColor: '#F2F2F2', height: 75, width: 500, borderRadius: 15, flexDirection: 'column',
        //                     display: 'flex', padding: 10, fontFamily: 'Nunito-Regular', color: '#43425D', fontSize: 16
        //                   }}>
        //                     <p style={{ flex: 5 }}>{item.comment}</p>
        //                     <p style={{ flex: 1, fontSize: 12 }}>{moment(parseInt(item.date)).format("DD MMM YY")}</p>
        //                   </div>
        //                 </Timeline.Item>;
        //               })}


        //             </Timeline></div>
        //         </foreignObject>


        //       </g>

        //     </g>



        //     <g style={{ display: showInfo ? 'block' : 'none' }}>
        //       <text className="_UploadInfoe" transform="translate(-6225 -48)">
        //         <tspan x={0} y={0}>
        //           {"RX Number"}
        //         </tspan>
        //       </text>
        //       <text className="_UploadInfof" transform="translate(-6281 23)">
        //         <tspan x={0} y={0}>
        //           {"Prescribed Doctor:"}
        //         </tspan>
        //       </text>
        //       <text className="_UploadInfoe" transform="translate(-6300 92.277)">
        //         <tspan x={0} y={0}>
        //           {"Previous Pharmacy:"}
        //         </tspan>
        //       </text>
        //       <g transform="translate(-7366 -610.342)">
        //         <rect
        //           className="_UploadInfog"
        //           width={326.443}
        //           height={54}
        //           rx={20}
        //           transform="translate(1321.647 527.342)"
        //         />
        //         <foreignObject width={326.443}
        //           height={54} transform="translate(1321.647 527.342)">
        //           <input
        //             type="number"
        //             value={data.newrxno}
        //             onChange={(e) => {
        //               console.log(e.target.value);
        //               data.newrxno = e.target.value
        //             }}
        //             placeholder="Label" style={{ backgroundColor: 'transparent', padding: 10, border: 0, width: '100%', height: '100%', fontSize: 18 }}></input>
        //         </foreignObject>

        //       </g>
        //       <g transform="translate(-7366 -603.243)">
        //         <rect
        //           className="_UploadInfog"
        //           width={327.464}
        //           height={54}
        //           rx={20}
        //           transform="translate(1321.647 591.242)"
        //         />
        //         <foreignObject width={327.464}
        //           height={54} transform="translate(1321.647 591.242)">
        //           <input
        //             value={data.prescribedDoctor}
        //             onChange={(e) => {
        //               console.log(e.target.value);
        //               data.prescribedDoctor = e.target.value
        //             }}
        //             placeholder="Dr. Josh Penn" style={{ backgroundColor: 'transparent', padding: 10, border: 0, width: '100%', height: '100%', fontSize: 18 }}></input>
        //         </foreignObject>



        //       </g>
        //       <g transform="translate(-7366 -609.531)">
        //         <rect
        //           className="_UploadInfog"
        //           width={326.443}
        //           height={57.175}
        //           rx={20}
        //           transform="translate(1321.647 667.944)"
        //         />
        //         <foreignObject width={326.443}
        //           height={54} transform="translate(1321.647 667.944)">

        //           <input placeholder="Label" value={data.previousPharmacy}
        //             onChange={(e) => {
        //               console.log(e.target.value);
        //               data.previousPharmacy = e.target.value
        //             }}
        //             style={{ backgroundColor: 'transparent', padding: 10, border: 0, width: '100%', height: '100%', fontSize: 18 }}></input>
        //         </foreignObject>


        //       </g>
        //       <g cursor="pointer" onClick={() => { console.log('ok'); onSubmit(data) }}>
        //         <rect
        //           className="_UploadInfoi"
        //           width={179}
        //           height={54}
        //           rx={20}
        //           transform="translate(-6044 146)"
        //         />
        //         <text className="_UploadInfoj" transform="translate(-5954 180)">
        //           <tspan x={-33.14} y={0}>
        //             {"Submit"}
        //           </tspan>
        //         </text>
        //       </g>
        //     </g>


        //     <rect
        //       className="_UploadInfoi"
        //       width={179}
        //       height={54}
        //       rx={20}
        //       transform="translate(-5706 -544)"
        //     />

        //     <text className="_UploadInfoj" transform="translate(-5616 -510)">
        //       <tspan x={-37.36} y={0}>
        //         {"Print Rx"}
        //       </tspan>
        //     </text>
        //     <text className="_UploadInfok" transform="translate(-6166 -173)">
        //       <tspan x={0} y={0}>
        //         {"Current Status: "}
        //       </tspan>
        //       <tspan className="_UploadInfob" y={0}>
        //         {data.status}
        //       </tspan>
        //     </text>
        //     <g transform="translate(-6292.757 -469.707)" >
        //       <g transform="translate(34 -35.5)">
        //         <rect
        //           className="_UploadInfol"
        //           width={161.035}
        //           height={256.112}
        //           rx={20}
        //           transform="translate(0 13.701)"
        //         />
        //         <rect className="_UploadInfom" width={500.514} height={283.514} rx={20} />
        //       </g>
        //     </g>
        //   </g>
        // </svg>

      }
    </>
  )
});

export default UploadInfo;
