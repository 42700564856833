/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const RecentRxItem = ({ data, onClick = () => { } }) => {
  if (!data) {
    data = { date: "blah", time: "blah", name: "blah", directions: "blah" }
  }
  return (
    <svg width={616.261} height={76} viewBox="0 0 616.261 76">
      <defs>
        <style>
          {
            "._RecentRxItema{fill:#fff;}._RecentRxItemb{fill:#f0f0f7;}._RecentRxItemc,._RecentRxIteme,._RecentRxItemf{fill:#646d82;}._RecentRxItemc,._RecentRxIteme{font-size:13px;}._RecentRxItemc{font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}._RecentRxItemd{fill:#272d5c;font-size:15px;}._RecentRxItemd,._RecentRxItemf{font-family:Nunito-Regular, Nunito;}._RecentRxIteme{font-family:SegoeUI, Segoe UI;}._RecentRxItemf{font-size:14px;}._RecentRxItemg{fill:rgba(255,255,255,0);}._RecentRxItemh{fill:url(#a);}._RecentRxItemi{clip-path:url(#b);}._RecentRxItemj{fill:url(#c);}._RecentRxItemk{fill:url(#e);}._RecentRxIteml{opacity:0.25;fill:url(#f);}._RecentRxItemm,._RecentRxItemn{opacity:0.2;}._RecentRxItemm{fill:url(#g);}"
          }
        </style>
        <linearGradient
          id="a"
          x1={0.125}
          y1={-0.865}
          x2={0.718}
          y2={1.268}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#2dc9eb" />
          <stop offset={1} stopColor="#14d2b8" />
        </linearGradient>
        <clipPath id="b">
          <circle
            className="_RecentRxItema"
            cx={20}
            cy={20}
            r={20}
            transform="translate(0.371 -0.031)"
          />
        </clipPath>
        <radialGradient
          id="c"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ff8181" />
          <stop offset={1} stopColor="#b72323" />
        </radialGradient>
        <linearGradient
          id="e"
          x1={0.462}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#4a7ae8" />
          <stop offset={1} stopColor="#263088" />
        </linearGradient>
        <radialGradient
          id="f"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#a6b2ee" />
          <stop offset={0.611} stopColor="#8f97fe" />
          <stop offset={1} stopColor="#221e85" />
        </radialGradient>
        <radialGradient
          id="g"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#86b3c7" stopOpacity={0.533} />
        </radialGradient>
      </defs>
      <path
        className="_RecentRxItemb"
        d="M3.374,0H612.887a3.2,3.2,0,0,1,3.374,3V73a3.2,3.2,0,0,1-3.374,3H3.374A3.2,3.2,0,0,1,0,73V3A3.2,3.2,0,0,1,3.374,0Z"
      />
      <text className="_RecentRxItemc" transform="translate(27 54)">
        <tspan x={0} y={0}>
          {data.date}
        </tspan>
      </text>
      <text className="_RecentRxItemc" transform="translate(27 34)">
        <tspan x={0} y={0}>
          {data.time}
        </tspan>
      </text>
      <text className="_RecentRxItemd" transform="translate(216 34)">
        <tspan x={0} y={0}>
          {data.name}
        </tspan>
      </text>
      <text className="_RecentRxIteme" transform="translate(214 54)">
        <tspan x={0} y={0}>
          {data.directions}
        </tspan>
      </text>
      {/* <text className="_RecentRxItemf" transform="translate(597 54)" style={{ cursor: 'pointer' }} onClick={() => { onClick(data.patid) }}>
        <tspan x={-75.894} y={0}>
          {"View more>"}
        </tspan>
      </text> */}
      <g transform="translate(138.762 7)">
        <rect className="_RecentRxItema" width={53.738} height={61.5} rx={15} />
        <rect className="_RecentRxItema" width={53.738} height={61.5} rx={8} />
        <g transform="translate(43.886 5.672)">
          <rect
            className="_RecentRxItemg"
            width={4}
            height={4}
            rx={2}
            transform="translate(0.352 0.328)"
          />
          <rect
            className="_RecentRxItemh"
            width={4}
            height={4}
            rx={2}
            transform="translate(0.352 0.328)"
          />
        </g>
        <g transform="translate(5.075 12.24)">
          <g className="_RecentRxItemi" transform="translate(1.791 1.791)">
            <path
              className="_RecentRxItemj"
              d="M420.981,511.525s9.382-4.656,18.041-1.2,8.377,6.055,15.309,4.966,9.5-6.217,19.952-5.976,11.77,6.94,21.844,6.94,18.453-6.94,18.453-6.94v19.722H424.86Z"
              transform="translate(-406.958 -488.345)"
            />
          </g>
          <g className="_RecentRxItemi" transform="translate(1.791 1.791)">
            <path
              className="_RecentRxItemk"
              d="M464.961,512.289s5.5-4.93,14.162-1.478,8.377,6.055,15.309,4.966,9.5-6.216,19.952-5.975,11.77,6.94,21.844,6.94,18.453-6.94,18.453-6.94v19.722h-89.72Z"
              transform="translate(-465.171 -488.835)"
            />
          </g>
          <circle
            className="_RecentRxIteml"
            cx={22}
            cy={22}
            r={22}
            transform="translate(0.163 -0.24)"
          />
          <path
            className="_RecentRxItemm"
            d="M2186.293,343.961s13.775,9.56,12.444,22.651-16.085,17.926-16.085,17.926,20.524,1.06,21.2-18.219S2186.293,343.961,2186.293,343.961Z"
            transform="translate(-2161.396 -342.073)"
          />
          <g className="_RecentRxItemn" transform="translate(0.56 5.526) rotate(-8)">
            <path
              className="_RecentRxItema"
              d="M.383,2.366A24.512,24.512,0,0,1,7.964.007c2.223-.133,1.977,1.617,1.311,1.828s-5.4,1.684-6.908,2.2S-.753,3.058.383,2.366Z"
              transform="translate(6.101 1.859) rotate(-11)"
            />
            <path
              className="_RecentRxItema"
              d="M0,4.3C.011,3.309,1.44.88,1.788.4A.961.961,0,0,1,3.007.108c.254.167.816.447.512,1.817A9.068,9.068,0,0,1,1.788,5.586C1.024,6.5-.01,5.283,0,4.3Z"
              transform="translate(1.329 6.401) rotate(13)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
};

export default RecentRxItem;
