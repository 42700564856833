import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Card, Image } from "react-bootstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import VitalIconBp from "../assets/images/vitals-icon-bp@2x.png";
const VitalHeader = observer(({ data }) => {
  console.log(data);
  if (!data) {
    data = {
      name: "Blood Pressure",
      key: "bloodPressure",
      systolic: 20,
      diastolic: 40,
      unit: "cmHg",
    };
  }

  const history = useHistory();

  const styles = {
    vitalText: {
      fontFamily: "Nunito-SemiBold",
      fontSize: 21,
      color: "white",
    },
    vitalValue: {
      fontFamily: "Nunito-SemiBold",
      fontSize: 36,
      color: "white",
    },
    bloodPressure: {
      backgroundImage: "linear-gradient(to top right, #FE8B6E , #FF6773)",
    },
    bloodGlucose: {
      backgroundImage: "linear-gradient(to top right, #9F64A8 , #6F66D1)",
    },
    temperature: {
      backgroundImage: "linear-gradient(to top right, #61C2D2 , #0B82BA)",
    },
    weight: {
      backgroundImage: "linear-gradient(to top right, #9692E9 , #574FFA)",
    },
    oxygenLevels: {
      backgroundImage: "linear-gradient(to top right, #FFB75E , #ED8F03)",
    },
  };
  return (
    <Row
      className={" align-items-center"}
      style={{
        ...{
          borderRadius: 30,
          boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
          border: "none",
          minHeight: 90,
        },
        ...styles[data.key],
      }}
    >
      <Col>
        <IoIosArrowRoundBack
          color="white"
          size={45}
          className="mx-2"
          type="button"
          onClick={() => history.goBack()}
        />
        <Image height={50} src={VitalIconBp} className="mx-3" />
      </Col>
      <Col>
        <div className="mx-3" style={styles.vitalText}>
          {data.name}
        </div>
      </Col>
      <Col style={{ marginLeft: "auto" }}>
        <div className="ml-auto mr-2" style={styles.vitalValue}>
          {data.key === "bloodPressure"
            ? `${data.systolic}|${data.diastolic}`
            : data.value}
        </div>
      </Col>
      <Col style={{ marginLeft: 20, marginRight: 20 }}>
        <div className="ml-3 mr-4" style={styles.vitalText}>
          {data.unit}
        </div>
      </Col>
    </Row>
  );
});
export default VitalHeader;
