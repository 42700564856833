
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import PtInfo from './PtInfo';
import PtRxList from './PtRxList';
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";

import ComplianceStatusCardList from './ComplianceStatusCardLIst';
import { dbstore } from '../dataLayer/stores/dbStore';
const PtDetails = observer(({ data, onEdit = () => { } }) => {
  let history = useHistory();

  if (!data) {
    data = {
      ptinfo: {},
      ptrxlist: []

    };
  } else {
    data.ptinfo.onchat = async () => {
      var status = await data.ptinfo.onchat1();
      history.push('/help')

      if (status == 200) {

      }

    }
  }
  return <>
    <Row gutter={[8, 8]} style={{ padding: 20, height: '90vh' }} className="drp-card">

      <Col span={12}>
        <div style={{ backgroundColor: "#F4F6F9", borderRadius: 10, padding: 30, height: '100%', width: '100%', marginRight: 10 }}>
          <PtInfo data={data.ptinfo} onEdit={onEdit} />
        </div>
      </Col>
      <Col span={12} >
        <div style={{ backgroundColor: "#F4F6F9", borderRadius: 10, padding: 30, height: '100%', width: '100%', marginLeft: 10 }}>
          <p style={{ fontFamily: "Nunito-SemiBold, Nunito", fontWeight: 600, color: "#272d5c", fontSize: 18, opacity: .5, marginBottom: 30 }}>COMPLIANCE FORMS</p>
          <ComplianceStatusCardList data={data.compliance} />

          <p style={{ fontFamily: "Nunito-SemiBold, Nunito", fontWeight: 600, color: "#272d5c", fontSize: 18, opacity: .5, marginBottom: 30, marginTop: 40 }}>ACTIVE PRESCRIPTIONS</p>
          <PtRxList data={{ arr: data.ptrxlist }} />
        </div>
      </Col>
    </Row>
  </>;
});
export default PtDetails;
