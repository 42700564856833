/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const HomePharmacy = ({ data }) => {
  if (!data) {
    data = { data: "blah" }
  }
  return (
    <svg width={360} height={124} viewBox="0 0 437.623 124">
      <defs>
        <style>
          {
            "._HomePharmacya{fill:#fff;stroke:#707070;}._HomePharmacyb{fill:#272d5c;font-size:20px;font-family:Nunito-Regular, Nunito;}._HomePharmacyc{fill:#a01027;}._HomePharmacyd{stroke:none;}._HomePharmacye{fill:none;}"
          }
        </style>
      </defs>
      <g transform="translate(-339 -582)">
        <g className="_HomePharmacya" transform="translate(339 582)">
          <rect className="_HomePharmacyd" width={437.623} height={124} rx={21} />
          <rect
            className="_HomePharmacye"
            x={0.5}
            y={0.5}
            width={436.623}
            height={123}
            rx={20.5}
          />
        </g>
        <text className="_HomePharmacyb" transform="translate(453.593 637)">
          <tspan x={0} y={0}>
            {`${data.data}`}
          </tspan>
        </text>
        <path
          className="_HomePharmacyc"
          d="M52.726,6H7.337v5.674H52.726Zm2.837,28.368V28.695L52.726,14.51H7.337L4.5,28.695v5.674H7.337V51.389H35.705V34.368H47.052V51.389h5.674V34.368ZM30.031,45.716H13.01V34.368H30.031Z"
          transform="translate(366.938 615)"
        />
      </g>
    </svg>
  )
};

export default HomePharmacy;
