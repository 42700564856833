import { inject, observer } from "mobx-react";
import ComplianceStatusCard from "./ComplianceStatusCard";
import { Row, Col } from "antd";
const ComplianceStatusCardList = observer(({ className, onClick = () => { }, data = null }) => {
  console.log(data)
  if (!data) {
    data = {
      hipaa: {
        name: "Hippa",
      },
      prescriptionTransfer: {
        name: "Prescription Transfer",
      },
      autoRefill: {
        name: "Auto Refill",
      },
      autoPay: {
        name: "Auto Pay",
      },
      autoDelivery: {
        name: "Auto Delivery",
      },
      digitalSignature: {
        name: "Digital Signature",
      },
      pillPackEnrollment: {
        name: "Pill Pack Enrollment",
      }
    }
  }
  return (
    <Row gutter={[16, 16]}>
      {Object.keys(data).map((v, i) => {
        return (
          <ComplianceStatusCard
            key={i + 1}
            compliance={data[v]["signed"]}
            className={"col-4 col-md-3"}
            onClick={onClick}
            compKey={v}
          />
        );
      })}
    </Row>
  );
});

export default ComplianceStatusCardList;
