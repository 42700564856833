import { makeAutoObservable, runInAction, toJS } from "mobx";
import _ from "lodash";

export default class ThemeStore {
  constructor() {
    makeAutoObservable(this);
  }
  currentTheme = {
    primary: "#DC635B",
    primaryBg: "#ffffff",
    primaryText: "#272D5C",
    whiteText: "#ffffff",
    sbStyle: "dark-content",
    sbBg: "#ffffff",
    noteBg: "#f2f2f2",
    secondaryBg: "#272D5C",
  };
  defaultTheme = {
    primary: "#DC635B",
    primaryBg: "#ffffff",
    primaryText: "#272D5C",
    whiteText: "#ffffff",
    sbStyle: "dark-content",
    sbBg: "#ffffff",
    noteBg: "#f2f2f2",
    secondaryBg: "#272D5C",
  };
  colors = {
    primaryText: "#272D5C",
    activeNavLink: "#A3A0FB",
    secondaryText: "#4D4F5C",
    maroon: "#A01027",
    green: "#23D098",
    orange: "#FD532F",
    yellow: "#FFCA83",
    lightYellow: "#FFF4E5",
    blue: "#4F73E0",
  };

}

const themestore = new ThemeStore();
export { themestore };

