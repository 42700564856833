
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import PtRxItem from './PtRxItem';
const PtRxList = observer(({ data }) => {
  if (!data) {
    data = {
      arr: [
        { completedRefill: "blah", rxname: "blah", rxtype: 'normal' },
        { completedRefill: "blah", rxname: "blah", rxtype: 'rr' },
        { completedRefill: "blah", rxname: "blah", rxtype: 'pa' }
      ]
    };
  }
  return <>
    <Row style={{ overflowY: 'scroll', height: 250, paddingRight: 5 }} >
      {data.arr.map((item, index) => {
        return <><PtRxItem data={item} /> </>;
      })}</Row>
  </>;
});
export default PtRxList;
