
import { Col, Row, Layout, Modal, Button, Input, message, Select } from 'antd'
import { useEffect, useState } from 'react'
import PtList from '../PtList';
import PtDetails from '../PtDetails';
import Triangle from '../Triangle';
import { patientsStore } from '../../dataLayer/stores/pages/patientsStore';
import { dbstore } from '../../dataLayer/stores/dbStore';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';

const { Option } = Select;

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Patients = observer(({ props }) => {
  const [show, setshow] = useState(false)
  const handleSelectFilter = (key) => {
    var keys = ['pillPackEnrollment']
    var val = '';
    switch (key) {
      case 'pillPackEnrollment':
        val = true;
        break;

    }
    if (key == 'none') {
      for (var i = 0; i < keys.length; i++) {
        dbstore.setPatientFilter(keys[i], '');

      }

      dbstore.getpatientDetails(true, 0, true)

      return
    }


    dbstore.setPatientFilter(key, val)
    dbstore.getpatientDetails(true, 0, true)

  }
  return <>
    <Row gutter={[8, 8]}>
      <Col span={8}>
        <div style={{ backgroundColor: '#fff', height: '90vh', marginTop: 15 }} className="drp-card">
          <Row>
            <Col span={14}>
              <div style={{ border: '1px solid #ddd', borderRadius: 20, padding: 0 }}>

                <Input placeholder="Search Name" allowClear bordered={false}
                  onChange={(e) => {
                    console.log(e.target.value);
                    dbstore.setPatientFilter('name', e.target.value)
                    dbstore.getpatientDetails(true, 0, true)

                  }} />
              </div>
            </Col>
            <Col span={10} style={{ paddingLeft: 5 }}>
              <div style={{ border: '1px solid #ddd', borderRadius: 20, padding: 0 }}>
                <Select defaultValue="none" style={{ width: '100%', margin: 0 }} bordered={false} onChange={(v) => { console.log(v); handleSelectFilter(v) }}>
                  <Option value="none">None</Option>
                  <Option value="pillPackEnrollment">Pill Pack Enrolled</Option>

                </Select>
              </div>
            </Col>
          </Row>
          <PtList data={patientsStore.comp1Data} onClick={(id) => { dbstore.setSelectedPatientId(id); }} />
        </div>
      </Col>
      <Col span={16}>
        <div style={box}>
          <PtDetails data={patientsStore.comp2Data} onEdit={() => setshow(true)} />
          <Triangle
            style={{
              position: "absolute",
              top: "25%",
              left: 0,
            }}
          />
        </div>
      </Col>

    </Row>
    <Modal
      visible={show}
      footer={false}
      //  onOk={handleOk} 
      onCancel={() => setshow(false)}
    >
      {Object.keys(patientsStore.comp3Data).length > 0 && <Row style={{ width: '100%' }} align="middle">
        <Col span={6} style={{ textAlign: 'right' }}><span>Email:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.email = e.target.value
          }}

          className="drp-input" value={patientsStore.comp3Data?.email || 'NA'} placeholder="Email"></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>Phone:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.phone = e.target.value
          }}
          className="drp-input" placeholder="Phone" value={patientsStore.comp3Data?.phone || 'NA'}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>Address Line:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.AddressLine = e.target.value
          }}
          className="drp-input" placeholder="Address Line" value={patientsStore.comp3Data?.address?.AddressLine || 'NA'}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>City:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.City = e.target.value
          }}
          className="drp-input" placeholder="City" value={patientsStore.comp3Data?.address?.City || 'NA'}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>State Code:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.StateCode = e.target.value
          }}
          className="drp-input" placeholder="State Code" value={patientsStore.comp3Data?.address?.StateCode || 'NA'}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>Zip Code:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.ZipCode = e.target.value
          }}
          className="drp-input" placeholder="Zip Code" value={patientsStore.comp3Data?.address?.ZipCode || 'NA'}></input></Col>
        <Col span={24} >
          <Button
            onClick={async () => {
              var obj = toJS(patientsStore.comp3Data);
              console.log(obj)
              setshow(false)
              var res = await dbstore.updatePatientDetails(obj)
              if (res) {
                message.success("Update Successfull!")
              } else {
                message.error('Oops! something went wrong')
              }
            }}
            className="drp-btn-green" style={{ display: 'block', width: 125, marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>Submit</Button>
        </Col>

      </Row>}
    </Modal>

  </>;
});
export default Patients;