import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
// import { dbstore } from "../dbStore";

export default class Store {
  comp1 = {};
  comp2 = { period: 30 };
  comp3 = {};
  comp4 = {};
  comp5 = {};
  comp6 = {
    visible: false,
  };
  constructor() {
    // this.dbstore = dbstore;

    makeAutoObservable(this, {
      setComp1: action,
      setComp2: action,
      setComp3: action,
      setComp4: action,
      setComp5: action,

      setComp6: action,
    });
  }

  setComp2(obj) {
    Object.assign(this.comp2, obj)
  }

  setComp6(obj) {
    Object.assign(this.comp6, obj);
  }
}

const homeActionStore = new Store();
export { homeActionStore };