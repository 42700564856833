
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { vitalsStore } from '../../dataLayer/stores/pages/vitalsStore';
import { vitalsActionsStore } from '../../dataLayer/stores/actions/vitalsActionsStore';
import { dbstore } from '../../dataLayer/stores/dbStore';
import VitalCardGroup from '../VitalCardGroup';
import VitalChart from '../VitalChart';
import VitalInfoCard from '../VitalInfoCard';
import VitalRecords from '../VitalRecords';
import { observer } from 'mobx-react';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const PatientVitals = observer(({ props }) => {
  useEffect(() => {

    dbstore.getpatientReports();
    vitalsActionsStore.setComp1('bloodPressure')

  }, [])
  return <><Row gutter={[8, 8]} style={{ width: '100%' }}>
    <Col span={24}>
      <div style={box}>
        <VitalCardGroup
          data={vitalsStore.comp1Data}
          onClick={(key) => { vitalsActionsStore.setComp1(key); console.log(key) }}
        />
      </div>
    </Col>
    <Col span={12}>
      <div style={box}>
        <Row style={{ padding: 20 }} className="drp-card">
          <VitalChart
            data={vitalsStore.comp2Data}
          /></Row>
      </div>
    </Col>
    <Col span={12}>
      <div style={box}>
        <VitalRecords
          limit={5} data={vitalsStore.comp3Data}
          showMore={true}
        />
      </div>
    </Col>
  </Row></>;
});
export default PatientVitals;