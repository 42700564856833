/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import { observer } from "mobx-react";
import React from "react";
import { patientsStore } from "../dataLayer/stores/pages/patientsStore";
import AnimImage from '../assets/images/Calendar-Day-M.png'
const PtItem = ({ data, onClick = () => { } }) => {
  if (!data) {
    data = { visitedDays: "blah", pname: "blah", page: "blah", pgender: "blah", _id: 1234 }
  }
  return (
    <div onClick={() => { onClick(data._id) }} style={{ backgroundColor: "#f4f6f9", cursor: 'pointer', width: '100%', marginBottom: 10, marginTop: 10, padding: 10, borderRadius: 15, display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'inline-block', }}>
        <img src={AnimImage} />
      </div>
      <div style={{ display: 'inline-block', marginLeft: 10, }}>
        <p style={{ fontWeight: 'bold', fontFamily: 'Nunito-Bold', fontSize: 14, marginTop: 0 }}>
          {data.pname},{data.page},{data.pgender}
        </p>
        <p>
          {`Last Visited ${data.visitedDays} Days `}
        </p>
      </div>
    </div>
  )
}
const PtItemOld = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = { visitedDays: "blah", pname: "blah", page: "blah", pgender: "blah", _id: 1234 }
  }
  return (
    <svg width={434.033} height={86.159} viewBox="0 0 434.033 86.159" style={{ cursor: 'pointer' }} onClick={() => { onClick(data._id) }}>
      <defs>
        <style>
          {
            "._PtItema,._PtItemg{fill:#fff;}._PtItema{stroke:#707070;}._PtItemb,._PtItemq{fill:none;}._PtItemb{stroke:rgba(224,228,235,0.61);stroke-miterlimit:10;stroke-width:3px;}._PtItemc{fill:#f4f6f9;}._PtItemd{fill:#6c7b8a;font-size:20px;font-family:Nunito-Regular, Nunito;}._PtIteme,._PtItemf{fill:#140f26;font-family:Nunito-SemiBold, Nunito;font-weight:600;}._PtIteme{font-size:16px;}._PtItemf{font-size:10px;}._PtItemh{fill:rgba(255,255,255,0);}._PtItemi{fill:url(#ax);}._PtItemj{clip-path:url(#b);}._PtItemk{fill:url(#c);}._PtIteml{fill:url(#e);}._PtItemm{opacity:0.25;fill:url(#f);}._PtItemn,._PtItemo{opacity:0.2;}._PtItemn{fill:url(#g);}._PtItemp{stroke:none;}"
          }
        </style>
        <linearGradient
          id="ax"
          x1={0.125}
          y1={-0.865}
          x2={0.718}
          y2={1.268}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#2dc9eb" />
          <stop offset={1} stopColor="#14d2b8" />
        </linearGradient>
        <clipPath id="b" >
          <ellipse


            className="_PtItema"
            cx={22}
            cy={21.5}
            rx={22}
            ry={21.5}
            transform="translate(-0.464 0.286)"
          />
        </clipPath>
        <radialGradient
          id="c"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ff8181" />
          <stop offset={1} stopColor="#b72323" />
        </radialGradient>
        <linearGradient
          id="e"
          x1={0.462}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#4a7ae8" />
          <stop offset={1} stopColor="#263088" />
        </linearGradient>
        <radialGradient
          id="f"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#a6b2ee" />
          <stop offset={0.611} stopColor="#8f97fe" />
          <stop offset={1} stopColor="#221e85" />
        </radialGradient>
        <radialGradient
          id="g"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#86b3c7" stopOpacity={0.533} />
        </radialGradient>
      </defs>
      <g className="_PtItemb">
        <rect className="_PtItemp" width={434.033} height={86.159} rx={15} />
        <rect
          className="_PtItemq"
          x={1.5}
          y={1.5}
          width={431.033}
          height={83.159}
          rx={13.5}
        />
      </g>
      <rect className="_PtItemc" width={434.033} height={86.159} rx={15} />
      <text className="_PtItemd" transform="translate(93.933 44.051)">
        <tspan x={0} y={20}>
          {`Last Visited ${data.visitedDays} Days `}
        </tspan>
      </text>
      <text className="_PtIteme" transform="translate(93.933 36.406)">
        <tspan x={0} y={0}>
          {`${data.pname},`} {`${data.page},${data.pgender}`}
        </tspan>
      </text>
      {/* <text className="_PtItemf" transform="translate(209.243 34.293)">
        <tspan x={0} y={0}>
          {`${data.page},${data.pgender}`}
        </tspan>
      </text> */}
      {/* <g transform="translate(15.547 9.069)">
        <rect className="_PtItemg" width={58.303} height={66.725} rx={15} />
        <rect className="_PtItemg" width={58.303} height={66.725} rx={15} />
        <g transform="translate(46.642 5.182)">
          <rect
            className="_PtItemh"
            width={4}
            height={4}
            rx={2}
            transform="translate(1.343 1.327)"
          />
          <rect
            className="_PtItemi"
            width={4}
            height={4}
            rx={2}
            transform="translate(1.343 1.327)"
          />
        </g>
        <g transform="translate(5.506 13.28)">
          <g clipPath="url(#b)" transform="translate(1.943 1.943)">
            <path
              className="_PtItemk"
              d="M420.981,511.735s10.179-5.052,19.573-1.306,9.089,6.57,16.609,5.387,10.31-6.745,21.647-6.483,12.77,7.529,23.7,7.529,20.02-7.529,20.02-7.529v21.4H425.189Z"
              transform="translate(-405.767 -486.586)"
            />
          </g>
          <g clipPath="url(#b)" transform="translate(1.943 1.943)">
            <path
              className="_PtIteml"
              d="M464.961,512.518s5.971-5.349,15.365-1.6,9.089,6.57,16.609,5.387,10.31-6.745,21.647-6.483,12.77,7.529,23.7,7.529,20.02-7.529,20.02-7.529v21.4H464.961Z"
              transform="translate(-465.189 -487.073)"
            />
          </g>
          <ellipse
            className="_PtItemm"
            cx={23.5}
            cy={24}
            rx={23.5}
            ry={24}
            transform="translate(0.479 0.229)"
          />
          <path
            className="_PtItemn"
            d="M2186.6,343.961s14.945,10.372,13.5,24.575-17.452,19.449-17.452,19.449,22.267,1.151,23-19.767S2186.6,343.961,2186.6,343.961Z"
            transform="translate(-2159.59 -341.913)"
          />
          <g className="_PtItemo" transform="translate(0.607 5.995) rotate(-8)">
            <path
              className="_PtItemg"
              d="M.415,2.568A26.6,26.6,0,0,1,8.641.008c2.412-.144,2.145,1.754,1.422,1.984s-5.856,1.827-7.5,2.387S-.817,3.318.415,2.568Z"
              transform="translate(6.619 2.017) rotate(-11)"
            />
            <path
              className="_PtItemg"
              d="M0,4.661C.012,3.59,1.562.955,1.94.433A1.042,1.042,0,0,1,3.263.118c.275.181.886.485.555,1.971A9.838,9.838,0,0,1,1.94,6.06C1.111,7.053-.011,5.732,0,4.661Z"
              transform="translate(1.442 6.945) rotate(13)"
            />
          </g>
        </g>
      </g> */}

    </svg>
  )
});

export default PtItem;
