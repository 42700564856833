
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Home from "../routes/home.js"

import Patients from "../routes/patients.js"

import PatientMessages from "../routes/patientMessages.js"

import PatientVitals from "../routes/patientVitals.js"

import PatientReports from "../routes/patientReports.js"

import PatientVitalDetails from "../routes/patientVitalDetails.js"

import PatientCalendar from "../routes/patientCalendar.js"

import TaskRefillRequests from "../routes/taskRefillRequests.js"

import TaskPriorAuths from "../routes/taskPriorAuths.js"

import TasksRxUploads from "../routes/tasksRxUploads.js"

import Reports from "../routes/reports.js"

import Profile from "../routes/profile.js"

import Help from "../routes/help.js"

const RouteSwitcher = (props) => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      
    <Route path="/home" exact>
        <Home />
    </Route>

    <Route path="/patients" exact>
        <Patients />
    </Route>

    <Route path="/patientMessages" exact>
        <PatientMessages />
    </Route>

    <Route path="/patientVitals" exact>
        <PatientVitals />
    </Route>

    <Route path="/patientReports" exact>
        <PatientReports />
    </Route>

    <Route path="/patientVitalDetails" exact>
        <PatientVitalDetails />
    </Route>

    <Route path="/patientCalendar" exact>
        <PatientCalendar />
    </Route>

    <Route path="/taskRefillRequests" exact>
        <TaskRefillRequests />
    </Route>

    <Route path="/taskPriorAuths" exact>
        <TaskPriorAuths />
    </Route>

    <Route path="/tasksRxUploads" exact>
        <TasksRxUploads />
    </Route>

    <Route path="/reports" exact>
        <Reports />
    </Route>

    <Route path="/profile" exact>
        <Profile />
    </Route>

    <Route path="/help" exact>
        <Help />
    </Route>

      <Route exact path="/">
        <Redirect to={"/home"} />
      </Route>
      <Route path="/">
        <>Page Not Found</>
      </Route>
    </Switch>
  );
};

export default RouteSwitcher;
