/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import { Button } from "antd";
import { observer } from "mobx-react";
import React from "react";
import BubbleStatusCard from "./BubbleStatusCard";
import ComplianceBubble from "./ComplianceBubble";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";

const RxDetails = observer(({ data, showRefill = () => { } }) => {
  let history = useHistory();
  // if (!data) {
  //   data = {
  //     rxname: "blah", docname: "blah", rxstartdate: "blah",
  //     rxstatus: "blah", lastrefill: "blah", rxenddate: "blah",
  //     refills: "blah", rxquantity: "blah", pname: "blah", page: "blah", gender: "blah", type: "rr"
  //   }
  // }
  if (data) {
    return (

      <svg width={902.3} height={"auto"} viewBox="0 0 902.3 812.9">
        <defs>
          <style>
            {
              "._RxDetailsa{fill:#f4f6f9;}._RxDetailsb{fill:#6c7b8a;}._RxDetailsb,._RxDetailsf{font-size:20px;}._RxDetailsb,._RxDetailse,._RxDetailsh,._RxDetailso,._RxDetailsq{font-family:Nunito-Regular, Nunito;}._RxDetailsc{fill:#140f26;}._RxDetailsc,._RxDetailsg{font-size:21px;}._RxDetailsc,._RxDetailsj,._RxDetailsl{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._RxDetailsd,._RxDetailse,._RxDetailsf,._RxDetailsg,._RxDetailsh,._RxDetailsi,._RxDetailsl{fill:#272d5c;}._RxDetailsd,._RxDetailsh{font-size:18px;}._RxDetailsd,._RxDetailsf,._RxDetailsg{font-family:Nunito-Bold, Nunito;font-weight:700;}._RxDetailse{font-size:16px;}._RxDetailsj,._RxDetailsk,._RxDetailso,._RxDetailsq{fill:#fff;}._RxDetailsj,._RxDetailsq{font-size:15px;}._RxDetailsl{font-size:12px;}._RxDetailsm{fill:#fe5431;}._RxDetailsn{fill:#23d098;}._RxDetailso{font-size:19px;}._RxDetailsp{fill:#fa644e;}.shadowFilter{filter:  drop-shadow(0px 0px 10px #bbb);"
            }
          </style>
        </defs>
        <g transform="translate(-914.5 -140.6)">
          <rect
            className="_RxDetailsa"
            width={902.3}
            height={812.9}
            rx={15}
            transform="translate(914.5 140.6)"
          />
          <foreignObject height="100" width="80" transform="translate(949.973 165.017)" className="shadowFilter">
            <BubbleStatusCard marginLeft={30} />
          </foreignObject>
          <text className="_RxDetailsb" transform="translate(1069.973 225.017)">
            <tspan x={0} y={20} xmlSpace="preserve">
              {`This Drug is Live for ${data.livePeriod}      `}
            </tspan>
          </text>

          <text className="_RxDetailsc" transform="translate(1069.973 213.613)">

            <tspan x={0} y={0}>
              {`${data.rxname} by ${data.docname}`}
            </tspan>
          </text>
          <g transform="translate(394.5 -63.242)">
            <text className="_RxDetailsd" transform="translate(740.5 501.242)">
              <tspan x={-59.229} y={0}>
                {`${data.rxstartdate}`}
              </tspan>
            </text>
            <text className="_RxDetailsd" transform="translate(750.5 652.242)">
              <tspan x={-56.467} y={0}>
                {`${data.rxstatus}`}
              </tspan>
            </text>
            <text className="_RxDetailsd" transform="translate(1194.5 650.242)">
              <tspan x={-45.81} y={0}>
                {`${data.lastrefill}`}
              </tspan>
            </text>
            <text className="_RxDetailsd" transform="translate(983.5 501.242)">
              <tspan x={-54.963} y={0}>
                {`${data.rxenddate}`}
              </tspan>
            </text>
            <text className="_RxDetailsd" transform="translate(1230.5 501.242)">
              <tspan x={-35.388} y={0}>
                {`${data.refills}`}
              </tspan>
            </text>
            <text className="_RxDetailsd" transform="translate(1031.5 645.242)">
              <tspan x={-55.368} y={0}>
                {`${data.rxquantity}`}
              </tspan>
            </text>
            <text className="_RxDetailse" transform="translate(742.5 458.242)">
              <tspan x={-36.88} y={0}>
                {"Start Date"}
              </tspan>
            </text>
            <text className="_RxDetailse" transform="translate(748.5 607.242)">
              <tspan x={-51.856} y={0}>
                {"Current Status"}
              </tspan>
            </text>
            <text className="_RxDetailse" transform="translate(989.5 602.242)">
              <tspan x={-47.984} y={0}>
                {"Last Quantity"}
              </tspan>
            </text>
            <text className="_RxDetailse" transform="translate(1194.5 605.242)">
              <tspan x={-27.168} y={0}>
                {"Last Fill"}
              </tspan>
            </text>
            <text className="_RxDetailse" transform="translate(983.5 458.242)">
              <tspan x={-33.008} y={0}>
                {"End Date"}
              </tspan>
            </text>
            <text className="_RxDetailse" transform="translate(1200.5 458.242)">
              <tspan x={-62.032} y={0}>
                {"Refills Remaining"}
              </tspan>
            </text>
          </g>
          <g transform="translate(392.5 224.758)">
            <text className="_RxDetailsf" transform="translate(1016.5 460.242)">
              <tspan x={-45.69} y={0}>
                {`${data.pname}`}
              </tspan>
            </text>
            <text className="_RxDetailsf" transform="translate(786.5 525.242)">
              <tspan x={-37.16} y={0}>
                {`${data.page}`}
              </tspan>
            </text>
            <text className="_RxDetailsg" transform="translate(1225.5 522.242)">
              <tspan x={-48.92} y={0}>
                {`${data.gender}`}
              </tspan>
            </text>
            <text className="_RxDetailsh" transform="translate(894.5 458.242)">
              <tspan x={-55.215} y={0}>
                {"Patient name:"}
              </tspan>
            </text>
            <text className="_RxDetailsh" transform="translate(726.5 523.242)">
              <tspan x={-18.621} y={0}>
                {"Age:"}
              </tspan>
            </text>
            <text className="_RxDetailsh" transform="translate(1112.5 521.242)">
              <tspan x={-16.956} y={0}>
                {"Sex:"}
              </tspan>
            </text>
          </g>
          <rect
            className="_RxDetailsi"
            width={822}
            height={34}
            rx={17}
            transform="translate(960 304)"
          />
          <rect
            className="_RxDetailsi"
            width={822}
            height={34}
            rx={17}
            transform="translate(960 617)"
          />
          <text className="_RxDetailsj" transform="translate(1047 325)">
            <tspan x={-62.58} y={0}>
              {"Medication Details"}
            </tspan>
          </text>
          <text className="_RxDetailsj" transform="translate(1047 639)">
            <tspan x={-65.505} y={0}>
              {"Patient Information"}
            </tspan>
          </text>
          <path
            className="_RxDetailsk"
            d="M13.7,17.5l5.682-5.923a1.04,1.04,0,0,1,1.517,0,1.161,1.161,0,0,1,0,1.584l-6.438,6.71a1.041,1.041,0,0,1-1.481.033L6.5,13.164a1.158,1.158,0,0,1,0-1.584,1.04,1.04,0,0,1,1.517,0Z"
            transform="translate(1737.162 305.013)"
          />
          <path
            className="_RxDetailsk"
            d="M13.7,17.5l5.682-5.923a1.04,1.04,0,0,1,1.517,0,1.161,1.161,0,0,1,0,1.584l-6.438,6.71a1.041,1.041,0,0,1-1.481.033L6.5,13.164a1.158,1.158,0,0,1,0-1.584,1.04,1.04,0,0,1,1.517,0Z"
            transform="translate(1737.162 618.013)"
          />
          <g transform="translate(1293.502 745)" className="shadowFilter" style={{ cursor: 'pointer' }} onClick={() => { history.push(`/patientVitals`); }}>
            <rect
              className="_RxDetailsk"
              width={135}
              height={50}
              rx={25}
              transform="translate(0.498)"

            />
            <text className="_RxDetailsl" transform="translate(49.498 29)">
              <tspan x={0} y={0}>
                {"View Vitals"}
              </tspan>
            </text>
            <g transform="translate(15.996 16.528)">
              <g transform="translate(0 0)">
                <path
                  className="_RxDetailsm"
                  d="M14.265,50.278a5.194,5.194,0,0,0-4.557,2.7,5.2,5.2,0,0,0-4.557-2.7A5.2,5.2,0,0,0,.661,53.01c-1.072,1.938-.7,4.073.152,6.017l.006.013H5.076l.976-1.758a.522.522,0,0,1,.911,0l1.792,3.2,2.772-5.834a.521.521,0,0,1,.961.046l1.577,4.342H18.6l.006-.013c.859-1.944,1.224-4.08.154-6.017A5.2,5.2,0,0,0,14.265,50.278Z"
                  transform="translate(0 -50.278)"
                />
                <path
                  className="_RxDetailsm"
                  d="M95.431,403.948l-.968-2.67-2.565,5.4a.523.523,0,0,1-.928.031l-1.865-3.335-.361.649a.525.525,0,0,1-.457.269H84.354c2.157,2.955,6.343,6,7.59,6.87a.628.628,0,0,0,.717,0c1.247-.874,5.433-3.917,7.59-6.87H95.923A.523.523,0,0,1,95.431,403.948Z"
                  transform="translate(-82.595 -393.957)"
                />
              </g>
            </g>
          </g>
          <g cursor="pointer" onClick={() => {
            if (data.type == 'pa') { window.open("https://account.covermymeds.com/", "_blank") }
            else { showRefill() }

          }}>
            <g className="shadowFilter" >
              <rect
                className="_RxDetailsn"
                width={182}
                height={data.type == 'pa' ? 80 : 53}
                rx={26.5}
                transform="translate(1279 851)"

              /></g>
            <text className="_RxDetailso" transform="translate(1364 883)">
              <tspan x={-69.493} y={0}>
                {data.type == 'rr' && "View Refill Status"}{data.type == 'pa' && "View PA Status on "}
              </tspan>
              <tspan x={-59.493} y={30}>
                {data.type == 'pa' && " Cover My Meds"}
              </tspan>
            </text></g>

        </g>
      </svg>
    )
  } else {
    return <svg width={902.3} height={"auto"} viewBox="0 0 902.3 812.9"></svg>
  }
});

export default RxDetails;
