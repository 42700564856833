/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const ContactCard = ({ data }) => {
  if (!data) {
    data = { name: "blah", tag: "blah" }
  }
  return (
    <svg width={360} height={124} viewBox="0 0 437.623 124">
      <defs>
        <style>
          {
            "._ContactCarda,._ContactCardf{fill:#fff;}._ContactCarda{stroke:#707070;}._ContactCardb{fill:#f5f5f5;}._ContactCardc,._ContactCardd{fill:#272d5c;}._ContactCardc{font-size:20px;font-family:Nunito-Regular, Nunito;}._ContactCardd{font-size:13px;font-family:Nunito-Light, Nunito;font-weight:300;}._ContactCarde{fill:#23d098;}._ContactCardg{fill:#fa644e;}._ContactCardh{stroke:none;}._ContactCardi{fill:none;}"
          }
        </style>
      </defs>
      <g className="_ContactCarda">
        <rect className="_ContactCardh" width={437.623} height={124} rx={21} />
        <rect
          className="_ContactCardi"
          x={0.5}
          y={0.5}
          width={436.623}
          height={123}
          rx={20.5}
        />
      </g>
      <g transform="translate(23.434 30)">
        <ellipse className="_ContactCardb" cx={32} cy={32.5} rx={32} ry={32.5} />
        <text className="_ContactCardc" transform="translate(76 25)">
          <tspan x={0} y={0}>
            {`${data.name}`}
          </tspan>
        </text>
        <text className="_ContactCardd" transform="translate(76 45)">
          <tspan x={0} y={0}>
            {`${data.tag}`}
          </tspan>
        </text>
      </g>
      <g transform="translate(254.934 43)">
        <circle
          className="_ContactCarde"
          cx={19.5}
          cy={19.5}
          r={19.5}
          transform="translate(0)"
        />
        <path
          className="_ContactCardf"
          d="M17.5,12.732l-3.941-1.689a.845.845,0,0,0-.985.243l-1.745,2.133A13.044,13.044,0,0,1,4.6,7.183L6.728,5.437a.842.842,0,0,0,.243-.985L5.282.511A.85.85,0,0,0,4.314.022L.655.866A.845.845,0,0,0,0,1.689,16.326,16.326,0,0,0,16.328,18.018a.845.845,0,0,0,.823-.655L18,13.7a.855.855,0,0,0-.493-.971Z"
          transform="translate(10.607 10.026)"
        />
      </g>
      <g transform="translate(307.339 46)">
        <path
          className="_ContactCardg"
          d="M32.64,24.565a1.883,1.883,0,0,1,.257-.948,2.618,2.618,0,0,1,.163-.241A12.6,12.6,0,0,0,35.2,16.356c.023-7.167-6.024-12.981-13.5-12.981A13.409,13.409,0,0,0,8.465,13.706a12.492,12.492,0,0,0-.288,2.658A13.186,13.186,0,0,0,21.47,29.509a16.008,16.008,0,0,0,3.669-.6c.878-.241,1.749-.56,1.974-.645a2.055,2.055,0,0,1,.723-.132,2.021,2.021,0,0,1,.785.155l4.407,1.562a1.051,1.051,0,0,0,.3.078.619.619,0,0,0,.622-.622,1,1,0,0,0-.039-.21Z"
          transform="translate(-2.868 -3.375)"
        />
        <path
          className="_ContactCardg"
          d="M24.4,29.148c-.28.078-.637.163-1.026.249a14.347,14.347,0,0,1-2.643.35A13.186,13.186,0,0,1,7.44,16.6a14.7,14.7,0,0,1,.117-1.663c.047-.334.1-.668.179-.995.078-.35.171-.7.272-1.042l-.622.552a11.568,11.568,0,0,0-4.011,8.7A11.439,11.439,0,0,0,5.3,28.527c.179.272.28.482.249.622s-.925,4.819-.925,4.819a.624.624,0,0,0,.21.6.633.633,0,0,0,.4.14.558.558,0,0,0,.225-.047l4.361-1.718a1.215,1.215,0,0,1,.933.016,13.083,13.083,0,0,0,4.718.933,12.208,12.208,0,0,0,9.336-4.283s.249-.342.536-.746C25.055,28.962,24.728,29.063,24.4,29.148Z"
          transform="translate(-3.375 -2.37)"
        />
      </g>
      <g transform="translate(353.624 43)">
        <circle className="_ContactCarde" cx={19.5} cy={19.5} r={19.5} />
        <g transform="translate(7.746 11.876)">
          <path
            className="_ContactCardf"
            d="M30.377,10.8a.978.978,0,0,0-.532.155l-4.951,3.125a.49.49,0,0,0-.229.412v4.35a.49.49,0,0,0,.229.412l4.951,3.125a1.029,1.029,0,0,0,.532.155h1.19a.487.487,0,0,0,.492-.486V11.286a.491.491,0,0,0-.492-.486Z"
            transform="translate(-6.42 -9.335)"
          />
          <path
            className="_ContactCardf"
            d="M16.072,23.651H5.2A2.957,2.957,0,0,1,2.25,20.71V11.942A2.952,2.952,0,0,1,5.192,9H16.066a2.952,2.952,0,0,1,2.942,2.942V20.7A2.944,2.944,0,0,1,16.072,23.651Z"
            transform="translate(-2.25 -9)"
          />
        </g>
      </g>
    </svg>
  )
};

export default ContactCard;
