
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { dbstore } from "../dbStore";

export default class Store {

  comp1 = {}
  comp2 = {}
  selectedPtr = null
  constructor() {
    makeAutoObservable(this, {
      setSelectedPtr: action
    });
  }

  get comp1Data() {
    if (!dbstore.prescriptionTransfers) {
      return
    }
    var obj = {
      arr: []
    };

    dbstore.prescriptionTransfers.forEach(item => {
      obj.arr.push({
        url: item.fileName, active: item._id == this.selectedPtr, id: item._id
      })
    });
    if (obj.arr.length > 0) {
      if (!this.selectedPtr) {
        obj.arr[0].active = true
        this.selectedPtr = obj.arr[0].id
      }

    }
    Object.assign(this.comp1, obj)
    console.log('run')
    return this.comp1
  }
  get comp2Data() {
    if (!dbstore.prescriptionTransfers || dbstore.prescriptionTransfers.length == 0) {
      return
    }
    var ptr = _.find(dbstore.prescriptionTransfers, { _id: this.selectedPtr })
    var obj = {
      status: 'Data Entry Pending',
      url: ptr.fileName,
      comments: ptr.comments,
      previousPharmacy: ptr.previousPharmacy,
      prescribedDoctor: ptr.prescribedDoctor,
      newrxno: ptr.newrxno ? ptr.newrxno : '', id: ptr._id
    }
    Object.assign(this.comp2, obj)
    return this.comp2
  }

  setSelectedPtr(id) {
    this.selectedPtr = id;
    console.log('selected')
    var d = this.comp1Data
  }
}

const rxUploadStore = new Store();
export { rxUploadStore };

