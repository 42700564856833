import { Col, Row, Layout, Menu, Affix } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
const NotificationMenu = (
  <Affix offsetTop={60}>
    <Menu>
      <Menu.Item key="0">1st menu item</Menu.Item>
      <Menu.Item key="1">2nd menu item</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu></Affix>
);
export default NotificationMenu;
