
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { homeActionStore } from "../actions/homeActionStore";
import { dbstore } from "../dbStore";
import moment from 'moment'
import { message } from "antd";
export default class Store {

  comp1 = {}
  comp2 = {}
  comp3 = {}
  comp4 = {}
  comp5 = {}
  comp6 = {}
  comp7 = {}
  timeCard = {
    type: 'in',
    time: ''
  }
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    var percentage = 50;
    if (dbstore.pharmacyReports) {
      percentage = (dbstore.pharmacyReports.pcAvg.taken / dbstore.pharmacyReports.pcAvg.total) * 100
      // console.log(percentage)
    }
    Object.assign(this.comp1, { percentage: percentage })
    return this.comp1

  }
  get comp2Data() {
    var period = 30;
    var datalist = [
      {
        date: "1622678400000",
        docid: "60ab4e837c0d2b4b74a9c979",
        missed: 3,
        patid: "60ab4e7a574c694b60994106",
        phid: "60aaaeb9cb311c3955146896",
        rxid: "60ac9bf4d06b017e5df767c3",
        taken: 2,
        total: 9,
        unanswered: 4,
      },
    ];
    if (dbstore.dailyPc) {
      period = homeActionStore.comp2.period;
      datalist = [];

      for (var i = 0; i < period; i++) {
        if (i < dbstore.dailyPc.length) {
          datalist.push(dbstore.dailyPc[i]);
        }
      }
      // datalist = this.dbstore?.pcarrDaily;
      // datalist = []
      // console.log(datalist)
    }
    Object.assign(this.comp2, { graphData: datalist, timePeriod: period })
    return this.comp2
  }
  get comp3Data() {
    if (!dbstore.rxDetails) {
      return
    }
    this.comp3 = _.filter(dbstore.rxDetails, { statuscode: 13 }).length
    return this.comp3
  }
  get comp4Data() {
    if (!dbstore.rxDetails) {
      return
    }
    this.comp4 = _.filter(dbstore.rxDetails, { transactionstatuscode: 2 }).length
    return this.comp4
  }
  get comp5Data() {
    return this.comp5
  }
  get comp6Data() {
    if (!dbstore.rxDetails) {
      return
    }
    if (!dbstore.patientDetails) { return }
    var obj = { arr: [] }

    var arr = _.sortBy(dbstore.rxDetails, ['filldate']).slice(0, 3)
    var ptarr = dbstore.patientDetails.slice(0, 3);
    // arr.forEach(rx => {
    //   var p = _.find(dbstore.patientDetails, { _id: rx.patid })
    //   if (p) ptarr.push(p)
    // });
    console.log(ptarr, 'period')
    ptarr.forEach((item, index) => {
      console.log(item)
      var o = {
        date: moment(parseInt(item.lastVisited)).format("DD MMM YY"),
        time: moment(parseInt(item.lastVisited)).format('HH:mm a'),
        name: item.name, id: item._id,
        avatarText: item.name.substr(0, 2).toUpperCase(),

        age: 34,
        gender: item.gender
      }
      obj.arr.push(o)
    });

    Object.assign(this.comp6, obj)
    return this.comp6
  }
  get comp7Data() {
    if (!dbstore.rxDetails) {
      return
    }
    var obj = { arr: [] }
    var arr = _.sortBy(dbstore.rxDetails, ['filldate']).slice(0, 3)
    arr.forEach(item => {
      var o = {
        date: moment(item.filldate).format("DD MMM YY"),
        time: moment(item.filldate).format('HH:mm a'), name: item.name,
        directions: item.directions, id: item._id, patid: item.patid,
      }
      obj.arr.push(o)
    });

    Object.assign(this.comp7, obj)
    return this.comp7
  }

  get timeCardData() {
    if (!dbstore.sessions) {
      return
    }
    var s = _.find(dbstore.sessions, { status: 'active' })
    var type = s ? 'out' : 'in'
    var duration = s ? moment.duration(moment().diff(moment(s.checkin, 'x'))) : ''
    var time = s ? Math.max(0, Math.floor(duration.asHours())) + 'h ' + Math.max(0, ('0' + Math.floor(duration.asMinutes() % 60)).slice(-2)) + 'm' : ''

    var obj = {
      type: type,
      time: time,
      onclick: async () => {
        var res;
        if (type == 'in') {
          res = await dbstore.clockin()
        } else {
          res = await dbstore.clockout()
        }
        if (res == 200) {
          message.success(`Clocked ${type}`)
        } else {
          message.error('Oops! something went wrong')
        }
      }
    }

    Object.assign(this.timeCard, obj);
    return this.timeCard
  }
}

const homeStore = new Store();
export { homeStore };

