
import {Col,Row,Layout} from 'antd'
import {useEffect,useState} from 'react'
var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
};
const PatientMessages = ({ props }) => {
    return <>PatientMessages</>;
  };
export default PatientMessages;