
import { Col, Row, Layout, Badge, Button } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { Colours } from '../Colours';
import '../App.css';
import { themestore } from '../dataLayer/stores/ThemeStore';
import T18Badge from './T18Badge';

const SmallInfoCard = observer(({ data, onClick = () => { } }) => {
  if (!data) {
    data = {
      info: 'You have pending',
      buttonText: 'Complete Now',
      highlight: 'Refill Requests'
    };
  }
  return <Row className="drp-card"
    align="middle" style={{ minHeight: 100, }}>
    <Col span={16}>
      <h4 className="drp-font-1" style={{ color: themestore.currentTheme.primaryText }}>{data.info}</h4>
      <h4 className="drp-font-1" style={{ color: themestore.colors.maroon }}>{data.highlight}</h4>
    </Col>
    <Col span={8}>
      <Button className="drp-btn-green btn-small" size="small" onClick={onClick}>
        {data.buttonText}
      </Button>
    </Col>
    <T18Badge count={data.count} style={{ backgroundColor: themestore.colors.maroon, fontFamily: "Nunito-Regular" }} />
  </Row>;
});
export default SmallInfoCard;
