
import { Col, Row, Layout, message } from 'antd'
import { useEffect, useState } from 'react'
import { dbstore } from '../../dataLayer/stores/dbStore';
import { profileStore } from '../../dataLayer/stores/pages/profileStore';
import ContactCard from '../ContactCard';
import HomePharmacy from '../HomePharmacy';
import ProfilePersonalDetails from '../ProfilePersonalDetails';
import TimelogItemList from '../TimelogItemList';
import Triangle from '../Triangle';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Profile = ({ props }) => {
  return <Row gutter={[16, 16]} style={{ width: '100%' }}>
    <Col span={8}>
      <div style={{ backgroundColor: '#fff', minheight: '90vh', marginTop: 15 }} className="drp-card">
        <ProfilePersonalDetails data={profileStore.comp1Data}
          uploadProfilePic={async (file) => {
            var res = await dbstore.newProfilePic(file); if (res == 200) {
              await dbstore.gettechnicianDetails(true)
              message.success("Profile Pic Successfully Changed");

            } else {
              message.error("Oops! something went wrong")
            }
          }}
        />
        <p style={{ fontSize: 16, fontFamily: 'Nunito-Regular', marginBottom: -10, fontWeight: 600, color: '#272D5C' }}>Your home clinic</p>
        <HomePharmacy data={profileStore.comp2Data} />
        <p style={{ fontSize: 16, fontFamily: 'Nunito-Regular', marginBottom: -10, fontWeight: 600, color: '#272D5C' }}>Contact your supervisor</p>

        <ContactCard />
      </div>
    </Col>
    <Col span={16}>
      <div style={{ backgroundColor: '#fff', height: '90vh', marginTop: 15 }} className="drp-card">
        <p style={{ fontSize: 20, fontFamily: 'Nunito-Regular', marginBottom: 10, fontWeight: 600, color: '#272D5C' }}>Full Timelog</p>
        <TimelogItemList data={profileStore.comp3Data} />
        <Triangle
          style={{
            position: "absolute",
            top: "25%",
            left: 0,
          }}
        />
      </div>
    </Col>
  </Row>;
};
export default Profile;