import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles/HomeStyle.module.css';
import { observer } from "mobx-react";
const DropInput = observer(({ onChange = () => { } }) => {
    return (
        <>
            <div className={Styles.inputfield}>
                <div>
                    <FontAwesomeIcon icon={'search'} size={'lg'} />
                    <input onChange={onChange} placeholder="Enter Last name,SSN, Date of Birth"></input>
                </div>
                <div>
                    <select>
                        <option>
                            None
                        </option>
                        <option>
                            Compilance
                        </option>
                    </select>
                </div>

            </div>
        </>
    );
})

export default DropInput;